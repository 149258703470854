import React from "react";
import { Link } from "react-router-dom";

import { helperService } from "../../services/helper.service";

import UserMenu from "../../components/UserMenu/UserMenu";

import "./Header.scss";

import logo from "../../assets/logo.svg";

interface IProps {}

const Header: React.FC<IProps> = (props) => {
  return (
    <div className="Header">
      <div className="logo"><Link to={helperService.isLoggedIn() ? "/events" : "/"}><img src={logo} alt="Eager Dog"/></Link></div>
      <div className="user">
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
