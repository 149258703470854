import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Config } from "@eagerdog/config";
import { Constants } from "@eagerdog/constants";
import { IAddress } from "@eagerdog/interfaces";
import moment from "moment";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions, Stripe } from '@stripe/stripe-js';
import { Helmet } from "react-helmet";

import { apiService } from "../../services";
import { helperService } from "../../services/helper.service";
import { IShow, IDog, IDogClass, EventDogShowsDto, ITransactionPaymentSummary, IUser, IShowElement, EventRegistrationCreateDto } from "@eagerdog/interfaces";

import ShowTime from "./ShowTime/ShowTime";
import Checkout from "./Checkout/Checkout";
import UserAddress, { isValidAddress, isValidPhone, IAddressError } from "./UserAddress/UserAddress";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Button from "src/components/Button/Button";
import Cart from "src/components/Cart/Cart";
import DogForm from "src/components/StepForm/DogForm/DogForm";
import FormBuilder from "src/components/FormBuilder/FormBuilder";
import Checkbox from "src/components/Checkbox/Checkbox";
import { Modal, useModal } from "src/components/Modal/Modal";
import DogPortrait from "src/components/DogPortrait/DogPortrait";
import Confirm from "src/components/Confirm/Confirm";
import RadioList, { IRadioListItem } from "src/components/RadioList/RadioList";

import { toast } from "src/components/Toast/ToastManager";

import "./EventRegister.scss";

interface IProps {}

export interface IDogInfo extends IDog {
  start_minutes?: number,
  time_slot?: string,
  classes?: IDogClass[],
  plan_to_earn_title?: boolean,
  title?: string,
  using_agent?: boolean,
  agent_name?: string
}

export interface IShowDogs extends IShow {
  dogs: IDogInfo[],
  registered_dogs?: number,
  created_by?: string,
  club_id: string
}

const EventRegister: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  let { eid } = useParams();
  let { isShown, toggle } = useModal();

  const user:IUser = helperService.getUser();

  const steps:string[] = ["Select Shows", "Dog Details", "Waiver", "Payment"];

  const [onBehalf, setOnBehalf] = useState<string>();
  const [onBehalfEmail, setOnBehalfEmail] = useState<string>();
  const [onBehalfJunior, setOnBehalfJunior] = useState<string>();
  const [isClaimed, setIsClaimed] = useState<boolean>(false);

  const [loadingBehalf, setLoadingBehalf] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [shows, setShows] = useState<IShowDogs[]>([]);
  const [event, setEvent] = useState<any>();
  const [currentShow, setCurrentShow] = useState<number>(-1);
  const [currentShowTime, setCurrentShowTime] = useState<number>(0);
  const [showTypes, setShowTypes] = useState<string[]>([]);
  const [dogs, setDogs] = useState<IDog[]>([]);
  const [agreeToWaiver, setAgreeToWaiver] = useState<boolean>(false);
  const [regPayload, setRegPayload] = useState<EventDogShowsDto[]>();

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();

  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [transactionId, setTransactionId] = useState<string>();

  const [modalContent, setModalContent] = useState<string>("");
  const [twoDogMsg, setTwoDogsMsg] = useState<string>("");

  const [dogDetailsError, setDogDetailsError] = useState<string>();

  const defaultPaymentSummary:ITransactionPaymentSummary = useMemo(() => {
    return {
      account_number: "",
      base_amount: 0,
      discount: 0,
      currency: "USD",
      fees: 0,
      total_amount: 0,
      details: []
    };
  }, []);

  const [userAddress, setUserAddress] = useState<IAddress>();
  const [userPhone, setUserPhone] = useState<string>();

  const [paymentSummary, setPaymentSummary] = useState<ITransactionPaymentSummary>(defaultPaymentSummary);

  const [clubPaymentMethods, setClubPaymentMethods] = useState<IRadioListItem[]>([]);
  const [cardToDelete, setCardToDelete] = useState<{label: string, id: string}>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number>(0);

  // DO - this will change...
  const onBehalfPaymentMethods: IOption[] = [
    { value: "Cash", id: Constants.payment_method_type.cash },
    { value: "E-Transfer", id: Constants.payment_method_type.etransfer },
    { value: "Paypal", id: Constants.payment_method_type.paypal },
    { value: "Cheque", id: Constants.payment_method_type.cheque },
    { value: "Other", id: Constants.payment_method_type.other }
  ];

  const akcTitles: IOption[] = [
    { value: "FCAT", id: Constants.akc_fastcat_titles.fcat},
    { value: "BCAT", id: Constants.akc_fastcat_titles.bcat},
    { value: "DCAT", id: Constants.akc_fastcat_titles.dcat}
  ];

  const [onBehalfPaymentMethod, setOnBehalfPaymentMethod] = useState<IOption>({ value: "Cash", id: Constants.payment_method_type.cash });  

  const getDogs = useCallback((id?: string, clubId?: string) => {
    apiService.getUserDogs(id ? id : user._id).then((dogResponse) => {
      if (clubId) {
        apiService.getUserDogs(id ? id : user._id, clubId).then((clubResponse) => {
          setDogs([...dogResponse, ...clubResponse]);
        });
      } else {
        if (dogResponse.length > 0) {
          setDogs(dogResponse);
        }
      }
    });
  }, [user._id]);

  const updatePaymentSummary = useCallback((discount?: string) => {
    let _shows:IShowDogs[] = JSON.parse(JSON.stringify(shows));
    let hasClasses:boolean = false;

    setDogDetailsError(undefined);

    let _payload:EventDogShowsDto[] = [];

    if (_shows.length > 0) {
      for (let s in _shows) {
        if (_shows[s].dogs.length > 0) {
          let _showDogs:any = {
            show_id: _shows[s]._id,
            dogs: []
          }

          for (let d in _shows[s].dogs) {
            let _dog:any = {
              dog_id: _shows[s].dogs[d]._id,
              dog_classes: _shows[s].dogs[d].classes,
              plan_to_earn_title: _shows[s].dogs[d].plan_to_earn_title !== undefined ? _shows[s].dogs[d].plan_to_earn_title : false,
              title: _shows[s].dogs[d].title,
              using_agent: _shows[s].dogs[d].using_agent !== undefined ? _shows[s].dogs[d].using_agent : false,
              agent_name: _shows[s].dogs[d].agent_name
            };

            let hasFastCat:any = _shows[s]?.dogs[d]?.classes?.filter((i: any) => { return i.time_slot !== undefined; });

            if (hasFastCat && hasFastCat.length > 0) {
              _dog.time_slot = hasFastCat[0].time_slot;
            }

            _showDogs.dogs.push(_dog);

            const _classLength = _shows[s]?.dogs[d]?.classes?.length;

            if (_classLength) {
              hasClasses = true;
            }
          }

          _payload.push(_showDogs);
        }
      }

      if (event && hasClasses) {
        let payload:EventRegistrationCreateDto = {
          registration_kind: onBehalf !== undefined && onBehalfEmail !== undefined ? Constants.registration_kind.club : Constants.registration_kind.owner,
          payment_method_type: onBehalf !== undefined && onBehalfEmail !== undefined ? onBehalfPaymentMethod.id.toLowerCase() : "card",
          "dog_show_registrations": _payload,
        }

        if (discount) {
          payload.discount_code = discount;
        }

        if (paymentIntentId) {
          payload.payment_intent_id = paymentIntentId;
        }

        if (transactionId) {
          payload.transaction_id = transactionId;
        }

        apiService.getRegistrationPaymentSummary(event._id, payload).then((response) => {
          if (response) {
            setRegPayload(_payload);
            setPaymentSummary(response);

            if (onBehalf === undefined && onBehalfEmail === undefined) {
              setStripePromise(loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY || "", { stripeAccount: response.account_number }));
            } else {
              setStripePromise(loadStripe(Config.web.REACT_APP_STRIPE_PUBLIC_KEY || ""));
            }

            if (discount && response.discount > 0) {
              toast.show({
                title: "Discount Applied",
                content: "A discount has been applied",
                duration: 10000,
                type: "success"
              });
            } else if (discount) {
              toast.show({
                title: "Discount Invalid",
                content: "The discount code you entered is invalid",
                duration: 10000,
                type: "fail"
              });
            }
          }
        }).catch((e: any) => {
          if (e.response?.data?.message) {
            setDogDetailsError(e.response?.data?.message);
          }

          toast.show({
            title: "Event Register",
            content: e.response?.data?.message ? e.response?.data?.message : "Something went wrong while getting the payment summary",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        });
      } else {
        setPaymentSummary(defaultPaymentSummary);
      }
    }
  }, [defaultPaymentSummary, event, shows, onBehalf, onBehalfEmail, paymentIntentId, transactionId, onBehalfPaymentMethod.id]);

  const updateDogInfo = (showIndex: number, dogIndex: number, classes: any[]) => {
    let _shows:IShowDogs[] = JSON.parse(JSON.stringify(shows));
    _shows[showIndex].dogs[dogIndex].classes = classes;

    setShows(_shows);
  }

  const updateDogInfoAllShowTypes = (showType: string, classes: IDogClass[], dog: IDog) => {
    let _shows:IShowDogs[] = JSON.parse(JSON.stringify(shows));

    for (let s in _shows) {
      if (_shows[s].show_type === showType) {
        let _dog:IDogInfo[] = _shows[s].dogs.filter((d: IDogInfo, index: number) => d._id === dog._id);

        if (_dog[0]) {
          _dog[0].classes = classes;
        }
      }
    }

    setShows(_shows);
  }

  /*const hasAddress = (validateDog: any) => {
    if (validateDog.coowners && validateDog.coowners.length > 0) {
      if ((!validateDog.coowners[0].address.city || validateDog.coowners[0].address.city.length === 0) ||
          (!validateDog.coowners[0].address.country || validateDog.coowners[0].address.country.length === 0) ||
          (!validateDog.coowners[0].address.state || validateDog.coowners[0].address.state.length === 0) ||
          (!validateDog.coowners[0].address.postal_code || validateDog.coowners[0].address.postal_code.length === 0) ||
          (!validateDog.coowners[0].address.line1 || validateDog.coowners[0].address.line1.length === 0)) {
        return validateDog.call_name + " is missing owner address information (Country, State, City, ZIP, and Address). You can add owner information by editing your dog information.";
      } else {
        return "";
      }
    }

    return "";
  }*/

  const validateAKCDogSelect = (selectedShow: IShow, validateDog: any) => {
    let errorMsg = "";

    switch (selectedShow.show_type) {
      case Constants.show_type.conformation:
        /*if (validateDog.coowners && validateDog.coowners.length === 0) {
          errorMsg = validateDog.call_name + " doesn't have owner information and can't register to this show. You can add owner information by editing your dog information.";
        }*/

        /*if (validateDog.coowners && validateDog.coowners.length > 0) {
          errorMsg = hasAddress(validateDog);
        }*/

        if (!validateDog.sire || validateDog.sire.length === 0) {
          errorMsg = validateDog.call_name + " is missing a Sire. You can add a sire by editing your dog information (under the basic information tab).";
        }

        if (!validateDog.dam || validateDog.dam.length === 0) {
          errorMsg = validateDog.call_name + " is missing a Dam. You can add a dam by editing your dog information (under the basic information tab).";
        }

        if (!validateDog.breeder || validateDog.breeder.length === 0) {
          errorMsg = validateDog.call_name + " is missing a breeder. You can add a breeder by editing your dog information (under the basic information tab).";
        }

        /*if (onBehalf === undefined && onBehalfEmail === undefined && ((!user.emergency_contact_name || user.emergency_contact_name.length === 0) || (!user.emergency_contact_phone || user.emergency_contact_phone.length === 0))) {
          errorMsg = "Your account is missing an emergency contact name and/or phone number. You can add emergency contact information on your account settings page (accessible in the top right corner).";
        }*/
      break;
      case Constants.show_type.fast_cat:
          /*if (validateDog.coowners && validateDog.coowners.length === 0) {
            errorMsg = validateDog.call_name + " doesn't have owner information and can't register to this show. You can add owner information by editing your dog information.";
          }*/

          /*if (validateDog.coowners && validateDog.coowners.length > 0) {
            errorMsg = hasAddress(validateDog);
          }*/

          if (!validateDog.sire || validateDog.sire.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Sire. You can add a sire by editing your dog information (under the basic information tab).";
          }

          if (!validateDog.dam || validateDog.dam.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Dam. You can add a dam by editing your dog information (under the basic information tab).";
          }
      break;
      case Constants.show_type.fetch:
          /*if (validateDog.coowners && validateDog.coowners.length === 0) {
            errorMsg = validateDog.call_name + " doesn't have owner information and can't register to this show. You can add owner information by editing your dog information.";
          }*/

          /*if (validateDog.coowners && validateDog.coowners.length > 0) {
            errorMsg = hasAddress(validateDog);

            if (validateDog.coowners[0].email === "") {
              errorMsg = validateDog.call_name + " doesn't have an owner email set and can't register to this show. You can add owner information by editing your dog information.";
            }

            if (validateDog.coowners[0].phone === "") {
              errorMsg = validateDog.call_name + " doesn't have an owner phone number set and can't register to this show. You can add owner information by editing your dog information.";
            }
          }*/

          if (!validateDog.sire || validateDog.sire.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Sire. You can add a sire by editing your dog information (under the basic information tab).";
          }

          if (!validateDog.dam || validateDog.dam.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Dam. You can add a dam by editing your dog information (under the basic information tab).";
          }
      break;
      case Constants.show_type.obedience:
          /*if (validateDog.coowners && validateDog.coowners.length > 0) {
            errorMsg = hasAddress(validateDog);
          }*/

          if (!validateDog.sire || validateDog.sire.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Sire. You can add a sire by editing your dog information (under the basic information tab).";
          }

          if (!validateDog.dam || validateDog.dam.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Dam. You can add a dam by editing your dog information (under the basic information tab).";
          }

          if (!validateDog.breeder || validateDog.breeder.length === 0) {
            errorMsg = validateDog.call_name + " is missing a breeder. You can add a breeder by editing your dog information (under the basic information tab).";
          }

          /*if (onBehalf === undefined && onBehalfEmail === undefined && ((!user.emergency_contact_name || user.emergency_contact_name.length === 0) || (!user.emergency_contact_phone || user.emergency_contact_phone.length === 0))) {
            errorMsg = "Your account is missing an emergency contact name and/or phone number. You can add emergency contact information on your account settings page (accessible in the top right corner).";
          }*/

          /*if (!validateDog.obedience_jump_height || validateDog.obedience_jump_height <= 0) {
            errorMsg = validateDog.call_name + " is missing an obedience jump height and can't register for this show. You can add an obedience jump height by editing your dogs information.";
          }*/
      break;
      case Constants.show_type.rally_obedience:
          /*if (validateDog.coowners && validateDog.coowners.length > 0) {
            errorMsg = hasAddress(validateDog);
          }*/

          if (!validateDog.sire || validateDog.sire.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Sire. You can add a sire by editing your dog information (under the basic information tab).";
          }

          if (!validateDog.dam || validateDog.dam.length === 0) {
            errorMsg = validateDog.call_name + " is missing a Dam. You can add a dam by editing your dog information (under the basic information tab).";
          }

          if (!validateDog.breeder || validateDog.breeder.length === 0) {
            errorMsg = validateDog.call_name + " is missing a breeder. You can add a breeder by editing your dog information (under the basic information tab).";
          }

          /*if (onBehalf === undefined && onBehalfEmail === undefined && ((!user.emergency_contact_name || user.emergency_contact_name.length === 0) || (!user.emergency_contact_phone || user.emergency_contact_phone.length === 0))) {
            errorMsg = "Your account is missing an emergency contact name and/or phone number. You can add emergency contact information on your account settings page (accessible in the top right corner).";
          }*/

          /*if (!validateDog.rally_jump_height || validateDog.rally_jump_height <= 0) {
            errorMsg = validateDog.call_name + " is missing an rally jump height and can't register for this show. You can add an rally jump height by editing your dogs information.";
          }*/
      break;
    }


    if (errorMsg.length > 0) {
      toast.show({
        title: "Registration",
        content: errorMsg,
        duration: 10000,
        type: "fail"
      });

      return false;
    } else {
      return true;
    }
  }

  const validateUKCDogSelect = (selectedShow: IShow, validateDog: any) => {
    let errorMsg = "";

    if (onBehalf === undefined && onBehalfEmail === undefined && user.is_junior) {
      let juniorEnabledShows:string[] = [
        Constants.show_type.nosework,
        Constants.show_type.conformation,
        Constants.show_type.obedience,
        Constants.show_type.rally_obedience,
        Constants.show_type.agility
      ];

      if (!juniorEnabledShows.includes(selectedShow.show_type)) {
        errorMsg = "Junior accounts cannot register to UKC " + selectedShow.show_type + " shows.";
      }
    }

    switch (selectedShow.show_type) {
      case Constants.show_type.rally_obedience:
        if (!validateDog.rally_jump_height || validateDog.rally_jump_height <= 0) {
          errorMsg = validateDog.call_name + " is missing an rally jump height and can't register for this show. You can add an rally jump height by editing your dogs information.";
        }
      break;
      case Constants.show_type.obedience:
        if (!validateDog.obedience_jump_height || validateDog.obedience_jump_height <= 0) {
          errorMsg = validateDog.call_name + " is missing an obedience jump height and can't register for this show. You can add an obedience jump height by editing your dogs information.";
        }
      break;
    }


    if (errorMsg.length > 0) {
      toast.show({
        title: "Registration",
        content: errorMsg,
        duration: 10000,
        type: "fail"
      });

      return false;
    } else {
      return true;
    }
  }

  const selectDog = (showIndex:number, dog:IDogInfo) => {
    let _shows:IShowDogs[] = JSON.parse(JSON.stringify(shows));
    let _foundDog:boolean = false;
    let _hasRegistrationNumber:boolean = dog.registration_numbers.filter((n) => event && n.name === event.sanctioning_club).length > 0;

    if (_shows[showIndex].excluded_breeds !== undefined && _shows[showIndex].excluded_breeds?.includes(dog.breed)) {
      toast.show({
        title: "Select Dog",
        content: dog.call_name + "'s dog breed ("+ dog.breed +") isn't allowed for this show",
        duration: 10000,
        type: "fail"
      });

      return false;
    }

    switch(event?.sanctioning_club) {
      case Constants.sanctioning_club.AKC:
        if (!validateAKCDogSelect(_shows[showIndex], dog)) {
          return false;
        }
      break;
      case Constants.sanctioning_club.UKC:
        if (!validateUKCDogSelect(_shows[showIndex], dog)) {
          return false;
        }
      break;
    }

    if ((_shows[showIndex].show_type === Constants.show_type.agility || _shows[showIndex].show_type === Constants.show_type.fast_cat) && (!dog.height_at_withers || dog.height_at_withers <= 0)) {
      toast.show({
        title: "Select Dog",
        content: dog.call_name + " doesn't have a value set for 'height at withers' and cannot register for your shows. You can add a value for your dogs 'height at withers' on your account settings page",
        duration: 10000,
        type: "fail"
      });
    } else if (!_hasRegistrationNumber && event) {
      toast.show({
        title: "Select Dog",
        content: dog.call_name + " doesn't have a(n) " + event.sanctioning_club + " registration number and cannot register for this event",
        duration: 10000,
        type: "fail"
      });
    } else if (_shows[showIndex].max_dogs !== undefined && (_shows[showIndex].max_dogs || 0) - (_shows[showIndex].registered_dogs || 0) === 0) {
      toast.show({
        title: "Dog Registration",
        content: "There aren't any available spots for that show",
        duration: 10000,
        type: "fail"
      });
    } else {
      let _dog = JSON.parse(JSON.stringify(dog));
      _dog.classes = [];

      for (let d in _shows[showIndex].dogs) {
        if (_shows[showIndex].dogs[d]._id === dog._id) {
          _shows[showIndex].dogs.splice(parseInt(d), 1);
          _foundDog = true;
          break;
        }
      }

      if (!_foundDog) {
        _shows[showIndex].dogs.push(_dog);
      }
      
      setShows(_shows);
    }
  }

  const showTypeHasDogs = (showType: string) => {
    for (let s in shows) {
      if (shows[s].show_type === showType) {
        if (shows[s].dogs.length > 0) {
          return shows[s].dogs.length;
        }
      }
    }

    return 0;
  }

  const allDogsHaveClasses = () => {
    for (let s in shows) {
      for (let d in shows[s].dogs) {
        let _classes = shows[s].dogs[d].classes;

        if (!helperService.timeSlotShows.includes(shows[s].show_type)) {
          if (!_classes || _classes.length === 0) {
            return false;
          }
        }
      }
    }

    return true;
  }

  const allDogsHaveAgents = () => {
    for (let s in shows) {
      for (let d in shows[s].dogs) {
        if (shows[s].dogs[d].using_agent && (shows[s].dogs[d].agent_name === undefined || shows[s].dogs[d].agent_name?.length === 0)) {
          return false;
        }
      }
    }

    return true;
  }

  const next = () => {
    let valid:boolean = true;

    let dogMsg:string = "";

    if (dogDetailsError !== undefined) {
      toast.show({
        title: "Event Registration",
        content: dogDetailsError,
        duration: 10000,
        type: "fail"
      });

      valid = false;
    }

    switch (currentStep) {
      case 0:
        let _hasDogs: boolean = false;

        for (let s in showTypes) {
          let hasDogs:number = showTypeHasDogs(showTypes[s]);

          if (hasDogs > 0) {
            _hasDogs = true;
          }

          if (hasDogs > 1) {
            if (dogMsg.length > 0) {
              dogMsg = "Looks like you've placed a few dogs onto a single show, multiple times. Was this intentional?";
            } else {
              dogMsg = "Looks like you've placed "+ hasDogs +" dogs onto a single " + showTypes[s] + " show. Was this intentional?";
            }

            valid = false;
          }
        }

        if (dogMsg) {
          setTwoDogsMsg(dogMsg);
          setModalContent("twoDogs");
        }

        if (!_hasDogs) {
          toast.show({
            title: "Event Registration",
            content: "Please add at least one dog to a show",
            duration: 10000,
            type: "fail"
          });

          valid = false;
        }
      break;
      case 1:
        if (!allDogsHaveClasses()) {
          toast.show({
            title: "Event Registration",
            content: "Each registered dog must have at least one class chosen per show",
            duration: 10000,
            type: "fail"
          });

          valid = false;
        }

        if (valid && !allDogsHaveAgents()) {
          toast.show({
            title: "Event Registration",
            content: "Missing an agent/handler name",
            duration: 10000,
            type: "fail"
          });

          valid = false;
        }

        let hasConf = shows.filter((s: IShow) => s.show_type === Constants.show_type.conformation);

        if (hasConf.length > 0) {
          for (let s in hasConf) {
            for (let d in hasConf[s].dogs) {
              let hasBrace = hasConf[s].dogs[d].classes?.filter((cl: IDogClass) => cl.level === "Brace");

              if (hasBrace && hasBrace.length > 0) {
                if (hasBrace[0].extra === undefined || hasBrace[0].extra?.length === 0) {
                  toast.show({
                    title: "Event Registration",
                    content: "Please enter a second registration # for Brace (" + hasConf[s].dogs[d].call_name + ")",
                    duration: 10000,
                    type: "fail"
                  });

                  valid = false;
                }
              }
            }
          }
        }

        let hasNoseworkOrRatRace = shows.filter((s: IShow) => s.show_type === Constants.show_type.nosework || s.show_type === Constants.show_type.urban_rat_race);

        if (hasNoseworkOrRatRace.length > 0) {
          for (let s in hasNoseworkOrRatRace) {
            for (let d in hasNoseworkOrRatRace[s].dogs) {
              let hasNone = hasNoseworkOrRatRace[s].dogs[d].classes?.filter((cl: IDogClass) => cl.section === "None");

              if (hasNone && hasNone.length > 0) {
                toast.show({
                  title: "Event Registration",
                  content: "Please select a Section for all the elements you've selected in your "+ hasNoseworkOrRatRace[s].show_type +" show(s)",
                  duration: 10000,
                  type: "fail"
                });

                valid = false;
                return;
              }
            }
          }
        }

        let hasFastCat = shows.filter((s: IShow) => s.show_type === Constants.show_type.fast_cat);

        if (hasFastCat.length > 0) {
          for (let s in hasFastCat) {
            for (let d in hasFastCat[s].dogs) {
              if (hasFastCat[s]?.dogs[d]?.classes?.length === 0) {
                toast.show({
                  title: "Event Registration",
                  content: "Please select a timeslot for all the elements you've selected in your "+ hasFastCat[s].show_type +" show(s)",
                  duration: 10000,
                  type: "fail"
                });

                valid = false;
                return;
              }
            }
          }
        }
      break;
      case 2:
        if (!agreeToWaiver) {
          valid = false;

          toast.show({
            title: "Event Registration",
            content: "You must agree to the Waiver to continue",
            duration: 10000,
            type: "fail"
          });
        }
      break;
    }

    if (valid) {
      let hasFastCat: any[] = shows.filter((s: any) => s.show_type === Constants.show_type.fast_cat);

      if (hasFastCat.length > 0) {
        checkFastCatDiffTimeSlots(hasFastCat);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  }

  const checkFastCatDiffTimeSlots = (hasFastCat: any[]) => {
    let dogMsg:string = "";
    let mergedTimeSlotsPerDog: any[] = [];

    for (let s in hasFastCat) {
      let _timeslot_name:string = "";

      for (let d in hasFastCat[s].dogs) {
        if (!mergedTimeSlotsPerDog[hasFastCat[s].dogs[d]._id]) {
          mergedTimeSlotsPerDog[hasFastCat[s].dogs[d]._id] = {
            name: hasFastCat[s].dogs[d].registered_name,
            timeSlots: []
          };
        }

        for (let c in hasFastCat[s].dogs[d].classes) {
          mergedTimeSlotsPerDog[hasFastCat[s].dogs[d]._id].timeSlots.push(hasFastCat[s].dogs[d].classes[c].time_slot);

          if (_timeslot_name.length === 0) {
            _timeslot_name = hasFastCat[s].dogs[d].classes[c].time_slot;
          } else if (_timeslot_name !== hasFastCat[s].dogs[d].classes[c].time_slot) {
            dogMsg = "Looks like you have multiple dogs in a single trial with different timeslots. Was this intentional?";
            break;
          }
        }
      }
    }

    if (Object.keys(mergedTimeSlotsPerDog).length === 1) {
      for (let d in mergedTimeSlotsPerDog) {
        for (let t in mergedTimeSlotsPerDog[d].timeSlots) {
          if (mergedTimeSlotsPerDog[d].timeSlots[t] !== mergedTimeSlotsPerDog[d].timeSlots[0]) {
            dogMsg = "Looks like "+ mergedTimeSlotsPerDog[d].name +" is registered for multiple trials, but in different timeslots. Was this intentional?";
          }
        }
      }
    }

    if (dogMsg.length > 0) {
      setTwoDogsMsg(dogMsg);
      setModalContent("twoDogs");
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  const previous = () => {
    setCurrentStep(currentStep - 1);
  }

  const getDogIds = () => {
    let dogIds:string[] = [];

    for (let s in shows) {
      for (let d in shows[s].dogs) {
        if (!dogIds.includes(shows[s].dogs[d]._id)) {
          dogIds.push(shows[s].dogs[d]._id);
        }
      }
    }

    return dogIds.join();
  }

  const dogInShowType = (dog:IDog, showType: string) => {
    let _filteredShows = shows.filter((show:IShowDogs) => show.show_type === showType && show.dogs.filter((d: IDogInfo) => d._id === dog._id).length > 0);

    return _filteredShows.length > 0 ? true : false;
  }

  const getJoinedShow = (dog:IDogInfo, showType: string) => {
    let _filteredShows = shows.filter((show:IShowDogs) => show.show_type === showType && show.dogs.filter((d: IDogInfo) => d._id === dog._id).length > 0);
    let _show:IShowDogs = JSON.parse(JSON.stringify(_filteredShows[0]));

    for (let s in shows) {
      if (shows[s].show_type === showType && shows[s].dogs.filter((d: IDogInfo) => d._id === dog._id).length > 0) {
        _show.show_elements = _show.show_elements.concat(shows[s].show_elements);
      }
    }

    _show.show_elements = _show.show_elements.filter((v:IShowElement, i:number, a:IShowElement[]) => a.findIndex(v2 => (JSON.stringify(v2) === JSON.stringify(v))) === i);

    return _show;
  }

   const getClubCards = (clubId: string) => {
    apiService.getClubPaymentMethods(clubId).then((clubPaymentMethodsResponse) => {
      let _radioItems:IRadioListItem[] = [];

      for (let i in clubPaymentMethodsResponse) {
        _radioItems.push({
          id: clubPaymentMethodsResponse[i].id,
          label: clubPaymentMethodsResponse[i].card.display_brand.toUpperCase() + " ending in " + clubPaymentMethodsResponse[i].card.last4,
          action: {
            label: "(remove)",
            onClick: () => { setModalContent("confirmRemoveCard"); setCardToDelete({ label: clubPaymentMethodsResponse[i].card.display_brand.toUpperCase() + " ending in " + clubPaymentMethodsResponse[i].card.last4, id: clubPaymentMethodsResponse[i].id }); }
          }
        })
      }

      setClubPaymentMethods(_radioItems);
    });
   }

   const toggleUsingAgentJoined = (_shows: IShowDogs[], _dog: any, _showType: string) => {
    let __shows = JSON.parse(JSON.stringify(_shows));

    for (let s in __shows) {
      if (__shows[s].show_type === _showType) {
        for (let d in __shows[s].dogs) {
          if (__shows[s].dogs[d]._id === _dog._id) {
            if (__shows[s].dogs[d].using_agent === undefined) {
              __shows[s].dogs[d].using_agent = true;
            } else {
              __shows[s].dogs[d].using_agent = !__shows[s].dogs[d].using_agent;
            }

            break;
          }
        }
      }
    }

    setShows(__shows);
   }

   const toggleUsingAgentSingle = (_showIndex: number, _dogIndex: number) => {
      let __shows = JSON.parse(JSON.stringify(shows));

      if (__shows[_showIndex].dogs[_dogIndex].using_agent) {
        __shows[_showIndex].dogs[_dogIndex].using_agent = false;
      } else {
        __shows[_showIndex].dogs[_dogIndex].using_agent = true;
      }

      setShows(__shows);
   }

   const updateAgentJoined = (_shows: IShowDogs[], _dog: any, _showType: string, _value: string) => {
    let __shows = JSON.parse(JSON.stringify(_shows));

    for (let s in __shows) {
      if (__shows[s].show_type === _showType) {
        for (let d in __shows[s].dogs) {
          if (__shows[s].dogs[d]._id === _dog._id) {
            __shows[s].dogs[d].agent_name = _value;
            break;
          }
        }
      }
    }

    setShows(__shows);
   }

   const updateAgentSingle = (_showIndex: number, _dogIndex: number, _value: string) => {
      let __shows = JSON.parse(JSON.stringify(shows));

      __shows[_showIndex].dogs[_dogIndex].agent_name = _value;

      setShows(__shows);
   }

   const toggleEarnTitle = (_shows: IShowDogs[], _dogIndex: number, _showIndex?: number, allShows?: true) => {
    let __shows = JSON.parse(JSON.stringify(_shows));

    if (_showIndex !== undefined) {
      let _dog = __shows[_showIndex].dogs[_dogIndex];

      if (_dog.plan_to_earn_title) {
        _dog.plan_to_earn_title = false;
      } else {
        _dog.plan_to_earn_title = true;
      }
    } else if (allShows) {
      for (let s in __shows) {
        let _dog = __shows[s].dogs[_dogIndex];

        if (_dog.plan_to_earn_title) {
          _dog.plan_to_earn_title = false;
        } else {
          _dog.plan_to_earn_title = true;
        }
      }
    }

    setShows(__shows);
   }

   const title : IOption = { value: "FCAT", id: Constants.akc_fastcat_titles.fcat };

   const updateTitle = (_shows: IShowDogs[], _dogIndex: number, title: IOption, _showIndex?: number, allShows?: true) => {
    let __shows = JSON.parse(JSON.stringify(_shows));

    if (_showIndex !== undefined) {
      let _dog = __shows[_showIndex].dogs[_dogIndex];

      _dog.title = title.value;
    } else if (allShows) {
      for (let s in __shows) {
        let _dog = __shows[s].dogs[_dogIndex];

        _dog.title = title.value;
      }
    }

    setShows(__shows);
   }

  // On Page Load, Redirect Club Managers, Get Event Data, Shows
  useEffect(() => {
    if (!loaded && eid) {
      apiService.getEventSummaryForHandle(eid).then((response) => {
        setEvent(response);
        if (response.status === Constants.event_status.draft) {
          if (user._id !== response.created_by) {
            toast.show({
              title: "Event",
              content: "This event hasn't been published yet",
              duration: 10000,
              type: "fail"
            });

            navigate("/events");
            return false;
          }
        }

        if (user.is_junior) {
          switch (response.sanctioning_club) {
            case Constants.sanctioning_club.UKC:
              if (user.junior_numbers?.filter((n: any) => { return n.name === Constants.sanctioning_club.UKC}).length === 0) {
                toast.show({
                  title: "Registration",
                  content: "You don't have a UKC Junior # attached to your account. Please add it on your account settings page.",
                  duration: 10000,
                  type: "fail"
                });

                navigate("/account");
              }
            break;
            case Constants.sanctioning_club.CKC:
              if (user.junior_numbers?.filter((n: any) => { return n.name === Constants.sanctioning_club.CKC}).length === 0) {
                toast.show({
                  title: "Registration",
                  content: "You don't have a CKC Junior # attached to your account. Please add it on your account settings page.",
                  duration: 10000,
                  type: "fail"
                });

                navigate("/account");
              }
            break;
            case Constants.sanctioning_club.AKC:
              if (user.junior_numbers?.filter((n: any) => { return n.name === Constants.sanctioning_club.AKC}).length === 0) {
                toast.show({
                  title: "Registration",
                  content: "You don't have an AKC Junior # attached to your account. Please add it on your account settings page.",
                  duration: 10000,
                  type: "fail"
                });

                navigate("/account");
              }
            break;
          }
        }

        const queryParameters = new URLSearchParams(window.location.search);
        const uid = queryParameters.get("uid");
        const uemail = queryParameters.get("uemail");
        const ujunior = queryParameters.get("junior");
        const claimed:boolean = queryParameters.get("claimed") === "true" ? true : false;
        setIsClaimed(claimed);

        if (user.user_type === "club_manager") {
          if (uid && uemail && ujunior && response.club.email === user.email) {
            setOnBehalf(uid);
            setOnBehalfEmail(uemail);
            setOnBehalfJunior(ujunior);

            if (response) {
              getClubCards(response.club_id);
            }
          } else {
            toast.show({
              title: "Event Registration",
              content: "Club owners aren't able to register to events",
              duration: 10000,
              type: "fail"
            });

            navigate("/events");
          }
        } else if (user.user_type === "") {
          toast.show({
            title: "Event Registration",
            content: "You must be logged in to register to an event",
            duration: 10000,
            type: "fail"
          });

          let _url = window.location.href.split("/e/")[1];

          if (_url) {
            navigate("/login?r=/e/" + _url);
          } else {
            navigate("/login");
          }

          return false;
        }

        if (response.club_id && response._id) {
          if (uid && uemail) {
            getDogs(uid, response.club_id);
          } else {
            getDogs(undefined, response.club_id);
          }

          apiService.getClubEventShows(response.club_id, response._id, {
            sort: [{
              attribute_name: "show_date",
              sort: "asc"
            }]
          }).then((eventResponse) => {
            let _showDogs:IShowDogs[] = [];

            for (let s in eventResponse) {
              _showDogs[s] = { ...eventResponse[s], dogs: [] };
            }

            setShows(_showDogs);

            let _showTypes:string[] = [];

            for (let t in eventResponse) {
              if (!_showTypes.includes(eventResponse[t].show_type)) {
                _showTypes.push(eventResponse[t].show_type);
              }
            }

            setShowTypes(_showTypes);
          });
        }
      });
    }

    setLoaded(true);
  }, [loaded, eid, getDogs, navigate, user.user_type, user.email, onBehalf, onBehalfEmail, user._id, user.is_junior, user.junior_numbers]);

  const prevShows = helperService.usePrevious(shows);
  const prevStep = helperService.usePrevious(currentStep);

  // On Payment Step, Get Payment Intent, Setup Payment Options
  useEffect(() => {
    if (prevStep !== currentStep) {
      if (prevStep === 0 && currentStep === 1) {
        setCurrentShow(0);
      }

      if (currentStep === 3 && event && regPayload) {
        if (onBehalf === undefined && onBehalfEmail === undefined) {
          apiService.createRegistrationPaymentIntent(event._id, {
            registration_kind: onBehalf !== undefined && onBehalfEmail !== undefined ? Constants.registration_kind.club : Constants.registration_kind.owner,
            payment_method_type: onBehalf !== undefined && onBehalfEmail !== undefined ? onBehalfPaymentMethod.id : "card",
            "dog_show_registrations": regPayload
          }).then((response) => {
            if (response) {
              setStripeOptions({
                clientSecret: response.client_secret
              });

              // DO
              // save the payment intent id
              setPaymentIntentId(response.payment_intent_id);
              setTransactionId(response.transaction_id);
            }
          }).catch((e: any) => {
            toast.show({
              title: "Event Register",
              content: e.response?.data?.message ? e.response?.data?.message : "Something went wrong when attempting to register this user",
              duration: 10000,
              errorDetails: e,
              type: "fail"
            });
          });
        } else {
          if (event && onBehalf !== undefined && onBehalfEmail !== undefined) {
            apiService.createClubPaymentMethod(event.club_id).then((response) => {
              if (response && response.client_secret) {
                setStripeOptions({
                  clientSecret: response.client_secret
                });
              }
            });
          }
        }
      }
    }
  }, [currentStep, prevStep, regPayload, event, setStripeOptions, onBehalf, onBehalfEmail, onBehalfPaymentMethod]);

  useEffect(() => {
    if (currentStep === 3 && prevStep === 3 && stripeOptions === undefined && clubPaymentMethods.length === 0) {
      if (event && onBehalf !== undefined && onBehalfEmail !== undefined) {
        apiService.createClubPaymentMethod(event.club_id).then((createResponse) => {
          if (createResponse && createResponse.client_secret) {
            setStripeOptions({
              clientSecret: createResponse.client_secret
            });
          }
        });
      }
    }
  }, [currentStep, prevStep, stripeOptions, setStripeOptions, clubPaymentMethods, event, onBehalf, onBehalfEmail])

  // On Dog Details, Update Dollar Amount on Dog Details Change
  useEffect(() => {
    if (JSON.stringify(prevShows) !== JSON.stringify(shows)) {
      updatePaymentSummary();
    }
  }, [shows, prevShows, updatePaymentSummary]);

  const getModalContent = () => {
    switch (modalContent) {
      case "dogForm":
        return <DogForm clubId={event?.club_id} onBehalf={onBehalf} onBehalfEmail={onBehalfEmail} onFinish={(dog: IDog) => {
          setModalContent("");
          toggle();
          const queryParameters = new URLSearchParams(window.location.search);
          const uid = queryParameters.get("uid");

          if (onBehalf && onBehalfEmail && event && uid) {
            getDogs(uid, event.club_id);
          } else if (event) {
            getDogs(undefined, event.club_id);
          }

          selectDog(currentShowTime, dog); }}
      />;
    case "confirmRemoveCard":
      return <Confirm
        message={cardToDelete !== undefined ? "Are you sure you want to remove "+ cardToDelete.label +"?" : ""}
        onConfirm={() => {
          if (event && cardToDelete) {
            apiService.deleteClubPaymentMethod(event.club_id, cardToDelete.id).then((response) => {
              apiService.getClubPaymentMethods(event.club_id).then((clubPaymentMethodsResponse) => {
                setClubPaymentMethods(clubPaymentMethodsResponse);
                setStripeOptions(undefined);
              });

              setCardToDelete(undefined);
              toggle();
            });
          }
        }}
        onCancel={() => { toggle(); }}
      />
    }

    return (
      <div className="twoDogsInner">
        <div className="title">Registration</div>
        <div className="message">{twoDogMsg}</div>
        <div className="actions">
          <Button onClick={() => { setModalContent(""); toggle(); }}>Back</Button>
          <Button onClick={() => { setCurrentStep(currentStep + 1); setModalContent(""); toggle(); }}>Yes</Button>
        </div>
      </div>
    );
  }

  const registerOnBehalf = () => {
    if (event && regPayload && onBehalf && onBehalfEmail) {
      setLoadingBehalf(true);

      let validAddress:IAddressError = isValidAddress(userAddress);
      let validPhone:IAddressError = isValidPhone(userPhone);

      if (validAddress.valid && validPhone.valid) {
        apiService.updateUserByClubManager(onBehalf, {
          address: userAddress,
          phone: userPhone
        }).then((userUpdateResponse) => {
          apiService.createRegistrationByClubManager(event._id, {
            registration_kind: Constants.registration_kind.club,
            payment_method_id: clubPaymentMethods[selectedPaymentMethod].id,
            payment_method_type: onBehalfPaymentMethod.id,
            "dog_show_registrations": regPayload,
            "owner_id": onBehalf
          }).then((response) => {
            navigate("/e/" + eid + "?dids=" + getDogIds() + "&redirect_status=succeeded&behalf=true");
          }).catch((e: any) => {
            toast.show({
              title: "Event Register",
              content: e.response?.data?.message ? e.response?.data?.message : "Something went wrong when attempting to register this user",
              duration: 10000,
              errorDetails: e,
              type: "fail"
            });
          }).finally(() => {
            setLoadingBehalf(false);
          });
        });
      } else {
        toast.show({
          title: "Event Register",
          content: validAddress.valid ? validPhone.message || "" : validAddress.message || "",
          duration: 10000,
          type: "fail"
        });
        setLoadingBehalf(false);
      }
    }
  }

  const pModalContent = helperService.usePrevious(modalContent);

  useEffect(() => {
    if (pModalContent !== modalContent) {
      if (modalContent !== "") {
        toggle();
      }
    } else {
      if (!isShown) {
        setModalContent("");
      }
    }
  }, [isShown, modalContent, pModalContent, setModalContent, toggle]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: paymentSummary.currency.toUpperCase() || "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const getWaiverTitle = (sanctioningClub: string) => {
    switch (sanctioningClub) {
      case Constants.sanctioning_club.UKC:
        return "UKC Waiver";
      case Constants.sanctioning_club.ABI:
        return "ABI Waiver";
      case Constants.sanctioning_club.AKC:
        return "AKC Waiver";
    }

    return "Waiver";
  }

  const getWaiverText = (sanctioningClub: string) => {
    switch (sanctioningClub) {
      case Constants.sanctioning_club.UKC:
        return <>
          <p>All events are held under the Official Rules and Regulations of the United Kennel Club. Absolutely no illegal drugs or firearms will be allowed on the grounds or in the buildings on the day of a UKC Licensed event. UKC, its agents and employees, and the host club assume no responsibility for any loss, damage, or injury sustained by spectators or by exhibitors and handlers, or to any of their dogs or property, and further assume no responsibility for injury to children not under the control of their parents or guardians. </p>
          <p>UKC and the host club are not responsible for loss, accidents or theft. By signing this form, I hereby agree to release, waive, discharge and covenant not to sue UKC, its judges, officers, employee and agents and the host club from liability arising from  any claim, action, or lawsuit, including the negligence of the UKC, its judges, officers, employee and agents, and further agree to indemnify and hold UKC, the host club and any approved UKC Judge harmless from any claims, actions or lawsuits, including attorneys' fees, resulting from my participation in this event, and any action, decision or judgment made by any UKC or host club representative or approved Judge under the official UKC Rules and Regulations governing this event.</p>
          <p>I acknowledge that the current Official UKC Rules and Regulations (Agility, Conformation, Dock Jumping, Drag Racing,  Junior Showmanship, Lure Coursing, Obedience, Rally Obedience, Weight Pull, Precision Coursing) have been made available to me, and that I am familiar with their contents.</p>
          <p><span>My signature indicates that I understand and agree to the above and to abide by all of the current Official UKC Rules and Regulations.</span> I have read this waiver of liability, assumption of risk, and indemnity agreement, fully understand its terms, and understand that I am giving up substantial rights, including my right to sue. I acknowledge that I am signing the agreement freely and voluntarily and intend by my signature to be a complete and unconditional release of all liability to the greatest extent allowed by law.</p>
        </>;
      case Constants.sanctioning_club.ABI:
        return <>
          <p>I (we) acknowledge that the Rules and Regulations of All Breed International Dog Shows (ABIDS) have been made available to me (us) on the website, and that I am (we are) familiar with the contents. I (we) agree that All Breed International Dog Shows (ABIDS) has the right to refuse this entry for cause, which the club shall deem to be sufficient. In consideration of the acceptance of this entry and of the holding of the show  and the  opportunity to have the dog judged and to win prize, ribbons or trophies, I (we) agree to hold All Breed International Dog Shows, Inc., their members, directors, governors, officers, agents, superintendents. show secretary or ring stewards and the owner or lessor of the premises and any employees of the aforementioned parties, harmless from any claim for loss or injury which may be alleged to have been caused directly or indirectly to any person or thing by the act of a dog while in or upon the show premises or grounds or near any entrance thereto, and I (we) personally assume all responsibility and liability for any such claim, and I (we) further agree to hold the aforementioned parties harmless from any claim for loss of a dog by disappearance, theft, death, or otherwise, and from any claim for damage or injury to the dog, whether such loss, disappearance, theft damage or injury, be caused or alleged to be caused by the negligence of All Breed International Dog Shows (ABIDS) or any of the parties aforementioned, or by the negligence of any other person, or any other cause or causes.</p>
          <p>I (we) hereby assume the sole responsibility for and to indemnify and save the aforementioned parties harmless from any and all loss and expenses (including legal fees) by reason of the liability imposed by law upon any of the aforementioned parties for damage because of bodily injuries, including death at any time resulting there from, sustained by any person or persons, including myself (ourselves), or on account of damage to property, arising out of or in consequence of my (our) participation in this show, howsoever such injuries, death or damage to property may be caused and whether or not the same may have been caused or may be alleged to have been caused by negligence of the aforementioned parties or any of their employees or agents, or any other persons.</p>
          <p>Judges may not judge dogs owned or co- owned by them.</p>
          <p>Entry fees will not be refunded in the event that a dog is, disqualified, or barred from competition by action of the Show Chairman or Show Secretary. All Breed International Dog Shows (ABIDS) reserves the right to refuse entries. Poor sportsmanship will not be tolerated at shows. If entered/paid and you are not able to attend the show monies will not be refunded to you & monies may not be put towards another show. If because of riots, civil disturbances or other acts beyond control of the management it is impossible to open or to complete the show, no entry fees will be refunded. Payment must be done by electronic transfer to dogsatoxford@gmail.com (Oxford Dog Sports) prior to the start of judging.</p>
          <p>Best of Breed Class:  Best of Breed competition is only for dogs that are ABIDS & FCI Champions a copy of FCI Championship must accompany your Entry Form. If according to owners or agents records your dog has completed the requirements for ABIDS Championship but Championship has not yet been confirmed by ABIDS office yet your win may be forfeited if no ABIDS Championship is actually acquired.</p>
        </>;
      case Constants.sanctioning_club.AKC:
        return <>
          <p>I (we) agree that the club holding this event has the right to refuse this entry for cause which the club shall deem sufficient.</p>
          <p>In consideration of the acceptance of this entry and of the holding of this event and of the opportunity to have the dog judged and to win prizes, ribbons, or trophies, I (we) agree to hold the AKC, the event-giving club, their members, directors, governors, officers, agents, superintendents or event secretary and the owner and/or lessor of the premises and any provider of services that are necessary to hold this event and any employees or volunteers of the aforementioned parties, and any AKC approved judge, judging at this event, harmless from any claim for loss or injury which may be alleged to have been caused directly or indirectly to any person or thing by the act of this dog while in or about the event premises or grounds or near any entrance thereto, and I (we) personally assume all responsibility and liability for any such claim; and I (we) further agree to hold the aforementioned parties harmless from any claim for loss, injury or damage to this dog.</p>
          <p>Additionally, I (we) hereby assume the sole responsibility for and agree to indemnify, defend and save the aforementioned parties harmless from any and all loss and expense (including legal fees) by reason of the liability imposed by law upon any of the aforementioned parties for damage because of bodily injuries, including death at any time resulting therefrom, sustained by any person or persons, including myself (ourselves), or on account of damage to property, arising out of or in consequence of my (our) participation in this event, however such, injuries, death or property damage may be caused, and whether or not the same may have been caused or may be alleged to have been caused by the negligence of the aforementioned parties or any of their employees, agents, or any other persons.</p>
          <p>I (WE) AGREE THAT ANY CAUSE OF ACTION, CONTROVERSY OR CLAIM ARISING OUT OF OR RELATED TO THE ENTRY, EXHIBITION OR ATTENDANCE AT THE EVENT BETWEEN THE AKC AND THE EVENT-GIVING CLUB (UNLESS OTHERWISE STATED IN ITS PREMIUM LIST) AND MYSELF (OURSELVES) OR AS TO THE CONSTRUCTION, INTERPRETATION AND EFFECT OF THIS AGREEMENT SHALL BE SETTLED BY ARBITRATION PURSUANT TO THE APPLICABLE RULES OF THE AMERICAN ARBITRATION ASSOCIATION. HOWEVER, PRIOR TO ARBITRATION ALL APPLICABLE AKC BYLAWS, RULES, REGULATIONS AND PROCEDURES MUST FIRST BE FOLLOWED AS SET FORTH IN THE AKC CHARTER AND BYLAWS, RULES, REGULATIONS, PUBLISHED POLICIES AND GUIDELINES.</p>
        </>;
    }

    return <></>;
  }

  const isJoinedShow = (_sanc: string, _type: string) => {
    if (_sanc === Constants.sanctioning_club.UKC) {
      let nonJoinedShowTypes:string[] = [
        Constants.show_type.nosework
      ];

      if (!nonJoinedShowTypes.includes(_type)) {
        return true;
      }
    }

    if (_sanc === Constants.sanctioning_club.AKC) {
      let nonJoinedShowTypes:string[] = [
        Constants.show_type.conformation,
        Constants.show_type.rally_obedience,
        Constants.show_type.obedience,
        Constants.show_type.nosework,
        Constants.show_type.fast_cat
      ];

      if (!nonJoinedShowTypes.includes(_type)) {
        return true;
      }
    }


    return false;
  }

  return (
    <div className="EventRegister">
      {event && <Helmet>
        <title>{event?.title} Event - Register</title>
        <meta name="description" content={"Register for " + event?.title + " event on " + moment(event?.start_date).format('dddd MMMM Do, yyyy') + "."} />
        <meta name="robots" content="index, follow" />
      </Helmet>}
      <div className={currentStep === 3 ? "registerWrap payNow" : "registerWrap"}>
        <div className={currentStep === 0 ? "registerInner noCart" : "registerInner"}>
          <div className="breadcrumb">{event?.title && <div className="path"><Link to={"/e/" + eid}>{event?.title}</Link> /</div>}<div className="current">Register</div></div>
          {onBehalf && onBehalfEmail && currentStep !== 3 && <div className="onBehalfMsg">
            <div className="msg">You're registering on behalf of <span>{onBehalfEmail}</span>.
              {(!isClaimed) && <div className="accountStatus"> This email doesn't have an active Eagerdog account, dogs on this account are managed by you.</div>}
              {(isClaimed) && <div className="accountStatus"> This email has an active Eagerdog account, displaying dogs created by both the user and you.</div>}
            </div>
          </div>}
          <div className="stepWrap">
            <ul className="steps">
              {steps.map((step:string, i:number) => {
                return(<li key={i} className={i === currentStep ? "active": i < currentStep ? "done" : ""}><span>{i + 1}</span><span>{step}</span></li>);
              })}
              <li className="actions">
                {(currentStep < steps.length - 1) && <button onClick={() => { next(); }}>Next</button>}
              </li>
            </ul>
            <div className="step">
              {loaded && currentStep === 0 && <div className="stepInner selectShows">
                {showTypes.map((showType:string, showTypeIndex:number) => {
                  return(
                    <div key={showTypeIndex} className={currentShow === showTypeIndex ? "showType expanded" : "showType"}>
                      <div onClick={() => { setCurrentShow(currentShow === showTypeIndex ? -1 : showTypeIndex); }} className="title">
                        <div className="name">{showType}</div>
                        <div className="actions"><span className="hint">{currentShow === showTypeIndex ? "Collapse" : "Expand"}</span><div className="icon caretDown"></div></div>
                      </div>
                      <div className="showTimes">
                        {shows.map((show:IShowDogs, showIndex:number) => {
                          if (show.show_type === showType) {
                            return(
                              <ShowTime
                                key={showIndex}
                                className={currentShowTime === showIndex ? "expanded" : ""}
                                show={show}
                                onToggleShow={() => { setCurrentShowTime(currentShowTime === showIndex ? -1 : showIndex); }}
                                onAddDog={() => { setModalContent("dogForm"); }}
                                onEditDog={() => {
                                  const queryParameters = new URLSearchParams(window.location.search);
                                  const uid = queryParameters.get("uid");

                                  if (onBehalf && onBehalfEmail && event && uid) {
                                    getDogs(uid, event.club_id);
                                  } else if (event) {
                                    getDogs(undefined, event.club_id);
                                  }
                                }}
                                onDogClick={(dog: IDog) => { selectDog(showIndex, dog) }}
                                dogs={dogs}
                                onBehalf={onBehalf !== undefined && onBehalfEmail !== undefined}
                                isClaimed={isClaimed}
                              />
                            );
                          } else {
                            return(null);
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>}
              {loaded && currentStep === 1 && <div className="stepInner dogDetails">
                {showTypes.map((showType:string, showTypeIndex:number) => {
                  if (showTypeHasDogs(showType) > 0) {
                    return(
                      <div key={showTypeIndex} className={currentShow === showTypeIndex ? "showType expanded" : "showType"}>
                        <div onClick={() => { setCurrentShow(currentShow === showTypeIndex ? 0 : showTypeIndex); }} className="title">
                          <div className="name">{showType}</div>
                          <div className="actions"><div className="icon caretDown"></div></div>
                        </div>
                        {event && isJoinedShow(event.sanctioning_club, showType) && dogs.map((dog:any, dogIndex:number) => {
                          if (dogInShowType(dog, showType)) {
                            let _show = getJoinedShow(dog, showType);
                            let _dog = _show.dogs.filter((d: any) => { return d._id === dog._id; }).length > 0 ? _show.dogs.filter((d: any) => { return d._id === dog._id; })[0] : undefined;

                            return(
                              <div key={dogIndex} className="show">
                                <div key={dogIndex} className="dog">
                                  <div className="dogDetails">
                                    <DogPortrait dog={dog} />
                                  </div>
                                  <div className="dogShowForm">
                                    {event && event.sanctioning_club === Constants.sanctioning_club.AKC && (showType === Constants.show_type.conformation || showType === Constants.show_type.obedience || showType === Constants.show_type.rally_obedience) && _dog !== undefined && 
                                      <div className="earnTitleWrap">
                                        <Checkbox id={"usingAgent"+dog.call_name+_show._id} onChange={(e) => { toggleUsingAgentJoined(shows, _dog, showType); }} value={_dog.using_agent ? _dog.using_agent : false} label={"I'm using an agent/handler for " + dog.call_name} />
                                      </div>
                                    }
                                    {_dog && _dog.using_agent &&
                                      <div className="agent">
                                        <Input required onChange={(e) => { updateAgentJoined(shows, _dog, showType, e.target.value); }} type="email" label="Agent/Handler Name" defaultValue={_dog.agent_name} placeholder="Enter your agent/handler name" />
                                      </div>
                                    }
                                    {_show !== null && <FormBuilder isJunior={onBehalfJunior !== undefined ? onBehalfJunior === "true" ? true : false : user.is_junior} event={event || undefined} show={_show} dog={dog} classes={_show.dogs.filter((d:IDogInfo) => d._id === dog._id)[0]?.classes || []} onChange={(classes: IDogClass[]) => { updateDogInfoAllShowTypes(_show.show_type, classes, dog); }} />}
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return(null);
                          }
                        })}
                        {event && !isJoinedShow(event.sanctioning_club, showType) && shows.map((show:IShowDogs, showIndex:number) => {
                          if (show.dogs.length > 0 && show.show_type === showType) {
                            return(
                              <div key={showIndex} className="show">
                                <div className="title">{show.show_name}</div>
                                <span className="time">{moment(show.show_date).format("dddd MMMM Do, YYYY @ h:mm A")}</span>
                                <div className="dogList">
                                  {show.dogs.map((dog:IDogInfo, dogIndex:number) => {
                                    return(<div key={dogIndex} className="dog">
                                      <div className="dogDetails">
                                        <DogPortrait dog={dog} />
                                      </div>
                                      <div className="dogShowForm">
                                        <div className="earnTitleWrap">
                                          {showType === Constants.show_type.fast_cat &&
                                            <div>
                                              <Checkbox id={"earnTitle"+dog.call_name+show._id} onChange={(e) => { toggleEarnTitle(shows, dogIndex, showIndex); }} value={dog.plan_to_earn_title ? dog.plan_to_earn_title : false} label={dog.call_name + " is Planning to Earn a Title"} />        
                                            </div>
                                          }
                                          {dog.plan_to_earn_title &&
                                            <Dropdown className="titleDrop" value={title} label={"Title"} onChange={(e, value: IOption) => { updateTitle(shows, dogIndex, value, showIndex, undefined); }} options={akcTitles} placeholder="" />
                                          }
                                          {event && event.sanctioning_club === Constants.sanctioning_club.AKC && (showType === Constants.show_type.conformation || showType === Constants.show_type.obedience || showType === Constants.show_type.rally_obedience) && dog !== undefined && 
                                            <div className="earnTitleWrap">
                                              <Checkbox id={"usingAgent"+dog.call_name+show._id} onChange={(e) => { toggleUsingAgentSingle(showIndex, dogIndex); }} value={dog.using_agent ? dog.using_agent : false} label={"I'm using an agent/handler for " + dog.call_name} />
                                            </div>
                                          }
                                          {dog && dog.using_agent &&
                                            <div className="agent">
                                              <Input required onChange={(e) => { updateAgentSingle(showIndex, dogIndex, e.target.value); }} type="email" label="Agent/Handler Name" defaultValue={dog.agent_name} placeholder="Enter your agent/handler name" />
                                            </div>
                                          }
                                        </div>
                                        <FormBuilder event={event || undefined} show={show} dog={dog} classes={dog.classes || []} onChange={(classes: IDogClass[]) => { updateDogInfo(showIndex, dogIndex, classes); }} />
                                      </div>
                                    </div>);
                                  })}
                                </div>
                              </div>
                            );
                          } else {
                            return(null);
                          }
                        })}
                      </div>
                    );
                  } else {
                    return(null);
                  }
                })}
              </div>}
              {loaded && currentStep === 2 && <div className="stepInner waiver">
                <div className="waiverInner">
                  <div className="waiverTitle">{getWaiverTitle(event ? event.sanctioning_club : "")}</div>
                  {getWaiverText(event ? event.sanctioning_club : "")}
                </div>
                <div className="waiverSign">
                  <div className="checkContainer">
                    <Checkbox onChange={(e) => { setAgreeToWaiver(!agreeToWaiver); }} value={agreeToWaiver} id="waiverSign" label={"I, "+ user.display_name +", have read and agree to the waiver on this form. I swear that this dog is not being handled by a professional handler in Conformation."} />
                  </div>
                  <div className="signature"></div>
                </div>
              </div>}
              {loaded && currentStep === 3 && <div className="stepInner payment">
                <div className="paymentWrap">
                  {onBehalf !== undefined && onBehalfEmail !== undefined && stripeOptions !== undefined && stripePromise !== undefined && <div className="behalfWrap warning">
                    <div className="behalfWarning">
                      <div className="behalfIcon"><div className="icon alert"><span></span></div></div>
                      <div className="behalfMsg">You're registering on behalf of <span>{onBehalfEmail}</span>. Fees (<span>{formatter.format(paymentSummary.fees || 0)}</span>) applied to the registration will be charged to your kennel club.</div>
                    </div>
                  </div>}
                  <div className="title">Handler Information</div>
                  <UserAddress
                    onBehalf={onBehalf !== undefined && onBehalfEmail !== undefined ? true : false}
                    user={user}
                    onChange={(address: IAddress, phone: string) => {
                      setUserAddress(address);
                      setUserPhone(phone);
                    }
                  } />
                  {onBehalf === undefined && onBehalfEmail === undefined && stripeOptions !== undefined && stripePromise !== undefined && 
                    <Elements stripe={stripePromise} options={stripeOptions}>
                      <Checkout
                        redirect={Config.web.REACT_APP_WEB_URL + "/e/" + eid + "?dids=" + getDogIds() }
                        userAddress={userAddress}
                        userPhone={userPhone}
                      />
                    </Elements>
                  }
                  {onBehalf !== undefined && onBehalfEmail !== undefined && stripeOptions !== undefined && stripePromise !== undefined && <div className="behalfWrap">
                    {clubPaymentMethods.length > 0 && <div className="clubHasCard">
                      <Dropdown value={onBehalfPaymentMethod} label={"Payment Method"} onChange={(e, value: IOption) => { setOnBehalfPaymentMethod(value); }} options={onBehalfPaymentMethods} placeholder="" />
                      <div className="title">Payment Information</div>
                      <RadioList
                        list={clubPaymentMethods}
                        selected={selectedPaymentMethod}
                        onSelect={(index: number) => { setSelectedPaymentMethod(index); }}
                      />
                      <div className="actions">
                        <Button isLoading={loadingBehalf} onClick={() => { registerOnBehalf(); }}>Confirm Payment</Button>
                      </div>
                    </div>}
                    {clubPaymentMethods.length === 0 && <>
                      <Elements stripe={stripePromise} options={stripeOptions}>
                        <Checkout
                          onBehalf={true}
                          onAddCard={() => {
                            if (event) {
                              getClubCards(event?.club_id);
                            }
                          }}
                          returnUrl={Config.web.REACT_APP_WEB_URL + "/e/" + eid + "/register"}
                          redirect={Config.web.REACT_APP_WEB_URL + "/e/" + eid + "?dids=" + getDogIds() }
                        />
                      </Elements>
                    </>}
                  </div>}
                </div>
              </div>}
            </div>
            <div className={currentStep === 0 ? "actions": "actions two"}>
              {currentStep !== 0 && currentStep !== 3 && <button onClick={() => { previous(); }}>Previous</button>}
              {(currentStep < steps.length - 1) && <button onClick={() => { next(); }}>Next</button>}
            </div>
          </div>
        </div>
        {currentStep !== 0 && <Cart onApplyDiscount={currentStep === 3 ? (discount: string) => { updatePaymentSummary(discount)} : undefined } shows={shows} paymentSummary={paymentSummary} />}
      </div>
      <Modal
        className={modalContent}
        isShown={isShown}
        hide={toggle}
        modalContent={getModalContent()}
      />
    </div>
  );
};

export default EventRegister;