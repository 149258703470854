import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { INoseworkScore, IFastcatScore, IFetchScore } from "@eagerdog/interfaces";

import { apiService } from "src/services/api.service";

import { toast } from "src/components/Toast/ToastManager";
import Button from "src/components/Button/Button";

import NoseworkResults from "./NoseworkResults/NoseworkResults";
import FastCatResults from "./FastCatResults/FastCatResults";
import FetchResults from "./FetchResults/FetchResults";

import "./EnterResults.scss";

interface IProps {
  showType: string,
  result: any,
  eventId: string,
  onSave(): void,
  onClose(): void
}

const EnterResults: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [results, setResults] = useState<any>();

  const hasValidSearchTime = () => {
    if (results.result_type !== Constants.score_result_type.qualified) {
      return true;
    }

    if (props.showType === Constants.show_type.nosework) {
      if (results && results.search_time) {
        let searchTimeSplit = results.search_time.split(':');

        if (searchTimeSplit.length === 3) {
          for (let s in searchTimeSplit) {
            if (searchTimeSplit[s].length !== 2) {
              // Incorrect Length
              return false;
            }

            let isNumber = parseInt(searchTimeSplit[s]);

            if (isNaN(isNumber)) {
              // Not a Number
              return false;
            }
          }
        } else {
          // Wrong Length
          return false;
        }
      } else {
        // Results or Search Time Don't Exist
        return false;
      }
    } else if (props.showType === Constants.show_type.fast_cat) {
      if (results && results.time.toString().length > 0) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  const enterResults = () => {
    if (hasValidSearchTime()) {
      if (results && results.result_type !== "None") {
        if (results && ((results.result_type !== Constants.score_result_type.qualified && results.result_reason !== "None" && results.result_reason !== "") || results.result_type === Constants.score_result_type.qualified || results.result_type === Constants.score_result_type.absent)) {
          switch (props.showType) {
            case Constants.show_type.nosework:
              apiService.enterNoseworkScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.fast_cat:
              apiService.enterFastcatScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.fetch:
              if (results.passed !== undefined) {
                apiService.enterFetchScore(props.eventId, props.result._id, results).then((response) => {
                  props.onSave();
                }).catch((error) => {
                  toast.show({
                    title: "Enter Results",
                    content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                    duration: 10000,
                    type: "fail"
                  });
                });
              } else {
                toast.show({
                  title: "Enter Results",
                  content: "Please enter a value for 'passed'",
                  duration: 10000,
                  type: "fail"
                });
              }
            break;
          }
        } else {
          toast.show({
            title: "Enter Results",
            content: "Please enter a result reason",
            duration: 10000,
            type: "fail"
          });
        }
      } else {
        toast.show({
          title: "Enter Results",
          content: "Please enter a result",
          duration: 10000,
          type: "fail"
        });
      }
    } else {
      toast.show({
        title: "Enter Results",
        content: "Please enter the search time in the correct format (two digits for each input)",
        duration: 10000,
        type: "fail"
      });
    }
  }

  const renderResults = () => {
    switch(props.showType) {
      case Constants.show_type.nosework:
        return <NoseworkResults result={props.result} onChange={(newResults: INoseworkScore) => {
          setResults(newResults);
        }} />;
      case Constants.show_type.fast_cat:
        return <FastCatResults result={props.result} onChange={(newResults: IFastcatScore) => {
          setResults(newResults);
        }} />;
      case Constants.show_type.fetch:
        return <FetchResults result={props.result} onChange={(newResults: IFetchScore) => {
          setResults(newResults);
        }} />;
    }
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <div className="EnterResults">
      <div className="title">
        <span>Enter Results</span>
        <span>{props.result.call_name}</span>
      </div>
      <form className="results-inner" onSubmit={(e) => { e.preventDefault(); enterResults(); }}>
        {renderResults()}
        <div className="actions">
          <Button type="button" onClick={() => { props.onClose(); }}>Cancel</Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </div>
  );
};

export default EnterResults;