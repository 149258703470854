import React, { useState, useEffect, useRef } from "react";
import { IEvent, EventDto, IClub, IAccountStatus } from "@eagerdog/interfaces";
import { AxiosError } from "axios";
import { toast } from "../../../components/Toast/ToastManager";
import { Link } from "react-router-dom";

import moment from "moment";
import { apiService } from "../../../services/api.service";

import StepForm, { Step, useStepForm } from "../StepForm";
import Input from "../../Input/Input";
import DatePick from "../../DatePick/DatePick";
import Phone from "../../Phone/Phone";
import Dropdown, { States, Provinces, IOption } from "../../Dropdown/Dropdown";

import date from "../../../assets/icons/date.svg";
import flagIcon from "../../../assets/icons/flag.svg";
import pinIcon from "../../../assets/icons/pin.svg";

import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import "./EventForm.scss";

interface IProps {
  onFinish(event: IEvent): void;
  event?: IEvent;
  prePopulate?: any;
}

const EventForm: React.FC<IProps> = (props) => {
  const { activeTab, switchToTab } = useStepForm();

  const [loaded, setLoaded] = useState<boolean>(false);

  const [currentClub, setCurrentClub] = useState<IClub>();
  const [accountStatus, setAccountStatus] = useState<IAccountStatus>();

  const [steps, setSteps] = useState<string[]>(
    props.event ? ["Name and Date", "Location", "Secretary", "Chairperson", "Advanced"] : [ "Name and Date", "Location", "Sanctioning Club", "Secretary", "Chairperson", "Advanced"]
  );

  const [eventName, setEventName] = useState<string>(
    props.event ? props.event.title : props.prePopulate ? props.prePopulate["eN"] : ""
  );

  const [startDate, setStartDate] = useState(
    props.event ? new Date(props.event.start_date) : props.prePopulate ? new Date(parseInt(moment(props.prePopulate["sD"]?.replace(",", "/")).format("X")) * 1000) : new Date()
  );

  const [eventLength, setEventLength] = useState<string>(
    props.event ? props.event.number_of_days.toString() : props.prePopulate ? props.prePopulate["eL"] : "2"
  );

  const [publishOption, setPublishOption] = useState<IOption>(
    props.event && moment(props.event.publish_at).format("X") > moment(new Date()).format("X") ? { value: "On a Specific Date", id: "On a Specific Date" } : props.prePopulate ? { value: props.prePopulate["pE"], id: props.prePopulate["pE"] } : { value: "Immediately", "id": "Immediately" }
  );

  const [publishDate, setPublishDate] = useState(
    props.event?.publish_at ? new Date(props.event.publish_at) : props.prePopulate ? new Date(parseInt(moment(props.prePopulate["pED"]?.replace(",", "/")).format("X")) * 1000) : new Date(Date.now() + 3600 * 1000 * 24)
  );

  const [eventAddress, setEventAddress] = useState<string>(
    props.event?.address?.line1 ? props.event.address.line1 : props.prePopulate ? props.prePopulate["eA"] : ""
  );

  const [eventAddress2, setEventAddress2] = useState<string>(
    props.event?.address?.line2 ? props.event.address.line2 : props.prePopulate ? props.prePopulate["eA2"] : ""
  );

  const [eventCity, setEventCity] = useState<string>(
    props.event?.address?.city ? props.event.address.city : props.prePopulate ? props.prePopulate["eCi"] : ""
  );

  const [eventCountry, setEventCountry] = useState<IOption>(
    props.event?.address?.country ? { value: props.event.address.country, id: props.event.address.country }  : props.prePopulate ? { value: props.prePopulate["eCo"], id: props.prePopulate["eCo"] } : { value: "USA", id: "USA" }
  );

  const [eventProvince, setEventProvince] = useState<IOption>(
    props.event?.address?.state ? { value: props.event.address.state, id: props.event.address.state }  : props.prePopulate ? { value: props.prePopulate["eP"], id: props.prePopulate["ePid"] } : { value: "Alberta", id: "AB" }
  );

  const [eventState, setEventState] = useState<IOption>(
    props.event?.address?.state ? { value: props.event.address.state, id: props.event.address.state }  : props.prePopulate ? { value: props.prePopulate["eP"], id: props.prePopulate["ePid"] } : { value: "Alabama", id: "AL" }
  );

  const [eventZip, setEventZip] = useState<string>(
    props.event?.address?.postal_code ? props.event.address.postal_code : props.prePopulate ? props.prePopulate["pC"] : ""
  );

  const [isIndoor, setIsIndoor] = useState<boolean>(
    props.event?.conditions.includes("Indoor") ? true : props.prePopulate ? props.prePopulate["i"] === "1" ? true : false : false
  );

  const [isOutdoor, setIsOutdoor] = useState<boolean>(
    props.event?.conditions.includes("Outdoor") ? true : props.prePopulate ? props.prePopulate["o"] === "1" ? true : false : false
  );

  const [sanctionClub, setSanctionClub] = useState<IOption>(
    props.event?.sanctioning_club ? { value: props.event?.sanctioning_club, id: props.event?.sanctioning_club }  : props.prePopulate ? { value: props.prePopulate["sC"], id: "UKC" } : { value: "UKC", id: "UKC" }
  );

  const editSecretary = props.event?.contacts.filter(
    (c) => c.contact_type === "secretary"
  )[0];

  const [secFirst, setSecFirst] = useState<string>(
    editSecretary ? editSecretary.first_name : props.prePopulate ? props.prePopulate["secF"] : ""
  );
  const [secLast, setSecLast] = useState<string>(
    editSecretary ? editSecretary.last_name : props.prePopulate ? props.prePopulate["secL"] : ""
  );
  const [secPhone, setSecPhone] = useState<string>(
    editSecretary ? editSecretary.phone : props.prePopulate ? props.prePopulate["secP"] : ""
  );
  const [secEmail, setSecEmail] = useState<string>(
    editSecretary ? editSecretary.email : props.prePopulate ? props.prePopulate["secE"] : ""
  );

  const editChair = props.event?.contacts.filter(
    (c) => c.contact_type === "chairperson"
  )[0];

  const [chairFirst, setChairFirst] = useState<string>(
    editChair ? editChair.first_name : props.prePopulate ? props.prePopulate["chaF"] : ""
  );
  const [chairLast, setChairLast] = useState<string>(
    editChair ? editChair.last_name : props.prePopulate ? props.prePopulate["chaL"] : ""
  );
  const [chairPhone, setChairPhone] = useState<string>(
    editChair ? editChair.phone : props.prePopulate ? props.prePopulate["chaP"] : ""
  );
  const [chairEmail, setChairEmail] = useState<string>(
    editChair ? editChair.email : props.prePopulate ? props.prePopulate["chaE"] : ""
  );

  const [stripeLink, setStripeLink] = useState<string>("");

  const sanctionClubs: IOption[] = [
    { value: "ABI", id: "ABI" },
    { value: "AKC", id: "AKC" },
    //{ value: "CKC", id: "CKC" },
    { value: "UKC", id: "UKC" },
  ];

  const [ isCreatingEvent, setIsCreatingEvent ] = useState<boolean>(false);

  const editorRef = useRef<TinyMCEEditor | null>(null);

  const registrationMessage = props.event?.registration_message ? props.event.registration_message : props.prePopulate ? props.prePopulate["rm"] : "";

  useEffect(() => {
    if (!loaded) {
      apiService.getCurrentClub().then((club) => {
        if (club._id) {
          setCurrentClub(club);

          apiService.getAccountStatus(club._id).then((status) => {
            setAccountStatus(status);

            if (status.onboarding_status !== "requirements_met" ||props.prePopulate) {
              let _steps: string[] = [...steps];
              _steps.push("Stripe");

              setSteps(_steps);

              if (props.prePopulate) {
                switchToTab(5);
                window.history.pushState("", "", window.location.href.split("?")[0]);
              }
            }
          });
        }
      });

      setLoaded(true);
    }
  }, [loaded, steps, props.prePopulate, switchToTab]);

  const getCondition = () => {
    let condition: string = "";

    if (isIndoor && isOutdoor) {
      condition = "Indoor & Outdoor";
    } else if (isIndoor) {
      condition = "Indoor";
    } else if (isOutdoor) {
      condition = "Outdoor";
    }

    return condition;
  };

  const validateEvent = () => {
    let passed: string = "";

    if (eventName === "") {
      passed = "Please set a name for your event";
      switchToTab(0);
    }

    if (parseInt(eventLength) < 1 || parseInt(eventLength) > 5) {
      passed = "Event length must be between 1 and 5 days";
      switchToTab(0);
    }

    if (eventAddress === "") {
      passed = "Please set an address for your event";
      switchToTab(1);
    }

    if (eventZip === "") {
      passed = "Please set a postal/zip code for your event";
      switchToTab(1);
    }

    if (
      (eventCountry.value === "Canada" && eventProvince.value === "") ||
      (eventCountry.value !== "Canada" && eventState.value === "")
    ) {
      passed = "Please select a province/state for your event";
      switchToTab(1);
    }

    if (eventCity === "") {
      passed = "Please set a city for your event";
      switchToTab(1);
    }

    if (getCondition() === "") {
      passed = "Please select Indoor, Outdoor, or both for your event";
      switchToTab(1);
    }

    if (secFirst === "" || secLast === "") {
      passed = "Please enter a first and last name for the event secretary";
      switchToTab(3);
    }

    if (chairFirst === "" || chairLast === "") {
      passed = "Please enter a first and last name for the event chairperson";
      switchToTab(4);
    }

    if (
      accountStatus?.onboarding_status === "past_due" ||
      accountStatus?.onboarding_status === "currently_due"
    ) {
      passed =
        "Your stripe account is missing important details, please add stripe details before creating an event";
      switchToTab(5);
    }

    return passed;
  };

  const createEvent = () => {
    let _error: string = validateEvent();

    if (_error.length > 0) {
      toast.show({
        title: "Event Error",
        content: _error,
        duration: 10000,
        type: "fail",
      });

      return false;
    }

    setIsCreatingEvent(true);

    let payload: any = {
      title: eventName,
      sanctioning_club: sanctionClub.value,
      conditions: getCondition(),
      start_date: startDate,
      arm_number_start: 100,
      number_of_days: parseInt(eventLength),
      publish_at: publishOption.value === "Immediately" ? new Date() : publishDate,
      status: publishOption.value === "Immediately" ? "published" : "draft",
      address: {
        line1: eventAddress,
        line2: eventAddress2,
        city: eventCity,
        state: eventCountry.value === "Canada" ? eventProvince.id : eventState.id,
        country: eventCountry.value,
        postal_code: eventZip,
      },
      contacts: [
        {
          contact_type: "secretary",
          first_name: secFirst,
          last_name: secLast,
          phone: secPhone,
          email: secEmail,
        },
        {
          contact_type: "chairperson",
          first_name: chairFirst,
          last_name: chairLast,
          phone: chairPhone,
          email: chairEmail,
        },
      ],
      registration_message: editorRef.current ? editorRef.current.getContent() : '',
      //show_types: []
    };

    if (props.event) {
      apiService.updateEvent(props.event._id, payload as EventDto).then((response) => {
        if (response._id) {
          props.onFinish(response);
        }
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Create Event",
          content: "Unable to update event",
          duration: 10000,
          errorDetails: e,
          type: "fail",
        });
      }).finally(() => {
        setIsCreatingEvent(false);
      });
    } else {
      apiService.createEvent(payload as EventDto).then((response) => {
        if (response._id) {
          props.onFinish(response);
        }
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Create Event",
          content: "Unable to create event",
          duration: 10000,
          errorDetails: e,
          type: "fail",
        });
      }).finally(() => {
        setIsCreatingEvent(false);
      });
    }
  };

  const createStripeLink = () => {
    if (currentClub) {
      let _returnUrl: string = createReturnUrl();

      apiService
        .getAccountOnboardingLink(currentClub._id, {
          return_url: _returnUrl,
          refresh_url: window.location.origin + "/events",
        })
        .then((response) => {
          if (response.url) {
            setStripeLink(response.url);
          }
        });
    }
  };

  const createReturnUrl = () => {
    let _url: string = window.location.origin + "/events?" +
      "stripe=1&" +
      "eN=" + eventName + "&" +
      "eL=" + eventLength + "&" +
      "eA=" + eventAddress + "&" +
      "eA2=" + eventAddress2 + "&" +
      "eCo=" + eventCountry.value + "&" +
      "eP=" + (eventCountry.value === "Canada" ? eventProvince.value : eventState.value) + "&" +
      "ePid=" + (eventCountry.value === "Canada" ? eventProvince.id : eventState.id) + "&" +
      "eCi=" + eventCity + "&" +
      "i=" + (isIndoor ? "1" : "0") + "&" +
      "o=" + (isOutdoor ? "1" : "0") + "&" +
      "sC=" + sanctionClub.value + "&" +
      "secF=" + secFirst + "&" +
      "secL=" + secLast + "&" +
      "secP=" + secPhone + "&" +
      "secE=" + secEmail + "&" +
      "chaF=" + chairFirst + "&" +
      "chaL=" + chairLast + "&" +
      "chaP=" + chairPhone + "&" +
      "chaE=" + chairEmail + "&" +
      "pC=" + eventZip + "&" +
      "sD=" + moment(startDate).format("M,DD,YYYY") + "&" +
      "pE=" + publishOption.value + "&" +
      "pED=" + moment(publishDate).format("M,DD,YYYY") + "&" +
      "rm=" + registrationMessage; //editorRef.current?.getContent();

    return encodeURI(_url);
  };



  return (
    <div className="EventForm">
      <div className="title">
        {props.event ? "Edit Event" : "Create an Event"}
      </div>
      <div className="formWrap">
        <StepForm activeTab={activeTab} next={(e) => {   switchToTab(e); }} onSubmit={createEvent} steps={steps} finishText={props.event ? "Update" : "Finish"} disabled={isCreatingEvent} isLoading={isCreatingEvent}>
          <Step>
            <div className="stepTitle">Name and Date</div>
            <p>What's the name and date of your event?</p>
            <Input required label="Event Name" defaultValue={props.event ? props.event.title : eventName} id="eventName" type="text" onChange={(e) => { e.stopPropagation(); setEventName(e.target.value); }} placeholder="What's your event's name?" />
            <DatePick label="Start Date" onChange={setStartDate} value={startDate} />
            <p>How many days is your event?</p>
            <Input required label="Event Length (days)" defaultValue={eventLength} id="eventLength" min="1" max="5" type="number" onChange={(e) => {   e.stopPropagation();   setEventLength(e.target.value); }} />
            <p>When do you want your event to be available to the public?</p>
            <Dropdown value={publishOption} onChange={(e: any, value: IOption) => { setPublishOption(value); }} icon={date} label="Publish my event..." options={[ { value: "Immediately", id: "Immediately" }, { value: "On a Specific Date", id: "On a Specific Date" } ]} placeholder="Choose a publishing option" />
            <>
            {publishOption.value === "On a Specific Date" && (<DatePick label="Publish Date" onChange={setPublishDate} value={publishDate} />)}
            </>
          </Step>
          <Step>
            <div className="stepTitle">Location</div>
            <p>Where is your event located?</p>
            <div className="half">
              <Input required label="Address Line 1" id="eventAddress" defaultValue={eventAddress} type="text" onChange={(e) => { e.stopPropagation(); setEventAddress(e.target.value); }} placeholder="What's your event's address?" />
              <Input label="Address Line 2" id="eventAddress2" type="text" defaultValue={eventAddress2} onChange={(e) => { e.stopPropagation(); setEventAddress2(e.target.value); }} placeholder=""/>
            </div>
            <div className="half">
              <Input required label="City" id="eventCity" defaultValue={eventCity} type="text" onChange={(e) => { e.stopPropagation(); setEventCity(e.target.value); }} />
              <Input required label="Postal/ZIP Code" id="eventZip" defaultValue={eventZip} type="text" onChange={(e) => { e.stopPropagation(); setEventZip(e.target.value); }} />
            </div>
            <div className="half">
              <Dropdown value={eventCountry} onChange={(e: any, value: IOption) => { setEventCountry(value); }} icon={flagIcon} label="Country" options={[ { value: "Canada", id: "Canada" }, { value: "USA", id: "USA" } ]} placeholder="Choose your country of residence" />
              <>
                {eventCountry.id === "USA" && (<Dropdown value={eventState} onChange={(e: any, value: IOption) => { setEventState(value); }} icon={pinIcon} label="State" options={States} placeholder="Choose your state" />)}
              </>
              <>
                {eventCountry.id === "Canada" && (<Dropdown value={eventProvince} onChange={(e: any, value: IOption) => { setEventProvince(value); }} icon={pinIcon} label="Province / State" options={Provinces} alignRight={true} placeholder="Choose your province" />)}
              </>
            </div>
            <p>Please select all that apply.</p>
            <div className="checkWrap half">
              <div className="checkboxWrap">
                <label className="checkboxLabel" htmlFor="isIndoor">
                  <div className="checkmarkWrap">
                    <input type="checkbox" id="isIndoor" name="isIndoor" checked={isIndoor} onChange={(e) => { setIsIndoor(!isIndoor); }} />
                    <span className="checkmark"></span>
                  </div>
                  <span className="name">Indoor</span>
                </label>
              </div>
              <div className="checkboxWrap">
                <label className="checkboxLabel" htmlFor="isOutdoor">
                  <div className="checkmarkWrap">
                    <input type="checkbox" id="isOutdoor" name="isOutdoor" checked={isOutdoor} onChange={(e) => { setIsOutdoor(!isOutdoor); }} />
                    <span className="checkmark"></span>
                  </div>
                  <span className="name">Outdoor</span>
                </label>
              </div>
            </div>
          </Step>
          <>
            {!props.event && (
              <Step>
                <div className="stepTitle">Sanctioning Club</div>
                <p>
                  What club is your event being run under? This can't be edited
                  after the event is created.
                </p>
                <Dropdown value={sanctionClub} onChange={(e: any, value: IOption) => { setSanctionClub(value); }} icon={flagIcon} label="Sanctioning Club" options={sanctionClubs} placeholder="Choose a sanctioning club" />
              </Step>
            )}
          </>
          <Step>
            <div className="stepTitle">Secretary</div>
            <p>Who is the secretary for your event?</p>
            <Input defaultValue={secFirst} required label="First Name" id="secFirst" type="text" onChange={(e) => { e.stopPropagation(); setSecFirst(e.target.value); }} placeholder="What's the secretary's first name?" />
            <Input defaultValue={secLast} required label="Last Name" id="secLast" type="text" onChange={(e) => { e.stopPropagation(); setSecLast(e.target.value); }} placeholder="What's the secretary's last name?" />
            <Phone label="Phone Number" value={secPhone} onChange={setSecPhone} />
            <Input defaultValue={secEmail} required label="Email" id="secEmail" type="email" onChange={(e) => { e.stopPropagation(); setSecEmail(e.target.value); }} placeholder="What's your secretary's email?" />
          </Step>
          <Step>
            <div className="stepTitle">Chairperson</div>
            <p>Who is the chairperson for your event?</p>
            <Input defaultValue={chairFirst} required label="First Name" id="chairFirst" type="text" onChange={(e) => { e.stopPropagation(); setChairFirst(e.target.value); }} placeholder="What's the chairperson's first name?" />
            <Input defaultValue={chairLast} required label="Last Name" id="chairLast" type="text" onChange={(e) => { e.stopPropagation(); setChairLast(e.target.value); }} placeholder="What's the chairperson's last name?" />
            <Phone label="Phone Number" value={chairPhone} onChange={setChairPhone} />
            <Input defaultValue={chairEmail} required label="Email" id="chairEmail" type="email" onChange={(e) => { e.stopPropagation(); setChairEmail(e.target.value); }} placeholder="What's your chairperson's email?" />
          </Step>
          <Step>
            <div className="stepTitle">Advanced</div>
            <p>Configure a custom message to include in registration emails.</p>
            <Editor 
              apiKey='e4kmr66e3tqyrdxqk84c9ucybc5l3qzaum53urpf5q3ccg54'
              onInit={(evt, editor) => editorRef.current = editor}
              init={{
                plugins: 'emoticons link lists media searchreplace table visualblocks wordcount',
                toolbar: 'undo redo | fontfamily fontsize | bold italic underline strikethrough | link image mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: false,
                branding: false,
                mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
                ],
              }}
              initialValue={registrationMessage}
              />
          </Step>
          <Step onStep={() => { createStripeLink(); }} >
            <div className="stepTitle">Stripe Account</div>
            <p>
              Eagerdog uses Stripe to process transactions, complete your Stripe
              account details so you're able to receive online event
              registrations. <span>This only needs to be done once!</span>{" "}
              Revenue from your events will be automatically deposited into your
              Kennel Club's bank account.
            </p>
            <>
              {(accountStatus?.onboarding_status === "past_due" ||
                accountStatus?.onboarding_status === "currently_due") && (
                <div className={"stripeError " + accountStatus?.onboarding_status}>
                  <div className="iconWrap">
                    <div className="icon alert">
                      <span></span>
                    </div>
                  </div>
                  <div className="message">
                    Your Stripe account is missing important details, you won't
                    be able to create an event until Stripe's requirements are
                    met.
                  </div>
                </div>
              )}
            </>
            <>
              {accountStatus?.onboarding_status === "eventually_due" && (
                <div className={"stripeError " + accountStatus?.onboarding_status}>
                  <div className="iconWrap">
                    <div className="success icon">
                      <span></span>
                    </div>
                  </div>
                  <div className="message">
                    There are some Stripe account details that will eventually
                    need to be provided, but Stripe is happy for now! You're
                    able to create your event and receive online registrations.
                  </div>
                </div>
              )}
            </>
            <>
              {accountStatus?.onboarding_status === "requirements_met" && (
                <div className={"stripeError " + accountStatus?.onboarding_status}>
                  <div className="iconWrap">
                    <div className="success icon">
                      <span></span>
                    </div>
                  </div>
                  <div className="message">
                    Your Stripe account details look good, you're able to create
                    your event and receive registrations.
                  </div>
                </div>
              )}
            </>
            <>
              {stripeLink === "" && (<span className="stripeLink disabled">Add Stripe Account Details</span>)}
            </>
            <>
              {stripeLink !== "" &&
                accountStatus?.onboarding_status !== "requirements_met" && (<Link className="stripeLink" to={stripeLink}>Add Stripe Account Details</Link>
                )}
            </>
          </Step>
        </StepForm>
      </div>
    </div>
  );
};

export default EventForm;
