import React from "react";
import { helperService } from "../../services/helper.service";

import { Constants } from "@eagerdog/Constants";
import { IDog, IUser } from "@eagerdog/interfaces";

import "./DogPortrait.scss";

import { Modal, useModal } from "../../components/Modal/Modal";
import DogForm from "../../components/StepForm/DogForm/DogForm";
import { IShowDogs } from "../../routes/EventRegister/EventRegister";

interface IProps {
  dog?: IDog,
  showName?: boolean,
  edit?: boolean,
  show?: IShowDogs,
  onEditDog?(): void
}

const DogPortrait: React.FC<IProps> = (props) => {
  const { isShown, toggle } = useModal();
  const user:IUser = helperService.getUser();

  const canEdit = () => {
    if (user.user_type !== Constants.user_type.club_manager) {
      return true;
    } else {
      if (props.show?.created_by === user._id && props.show.club_id === props.dog?.club_id) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <div className="DogPortrait">
        <div className="imageWrap">
          <>{props.dog?.image_url && <img src={props.dog?.image_url} alt="Dog Portrait" />}</>
          <>{(props.dog?.image_url === undefined || props.dog?.image_url.length === 0) && <span className="initial">{props.dog?.call_name.substring(0, 1).toUpperCase()}</span>}</>
        </div>
        {props.showName !== false && <span className="dogName">{props.dog?.call_name}</span>}
        {props.edit && canEdit() && <div className="editWrap" onClick={(e) => { e.stopPropagation(); e.preventDefault(); toggle(); }}>
          <div className="editIcon"></div>
        </div>}
        <Modal
          className={"dogForm"}
          isShown={isShown}
          hide={toggle}
          modalContent={<DogForm dog={props.dog} onFinish={() => { toggle(); if (props.onEditDog) { props.onEditDog(); } }} />}
        />
    </div>
  );
};

export default DogPortrait;