import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { helperService, IDogPortrait } from "../../services/helper.service";
import { AuthDto } from "@eagerdog/interfaces";
import { Helmet } from "react-helmet";

import "../../styles/Signup.scss";

import logo from "../../assets/logo.svg";
import emailIcon from "../../assets/icons/email.svg";
import keyIcon from "../../assets/icons/key.svg";

import Input from "../../components/Input/Input";
import { toast } from "../../components/Toast/ToastManager";
import { apiService } from "../../services";

interface IProps {}

const Login: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  let [email, setEmail] = useState<string>("");
  let [password, setPassword] = useState<string>("");

  let _url = window.location.href.split("/e/")[1];
  let registerLink = _url ? `/register/dog-owner?r=/e/${_url}` : "/register";
  const resetPasswordLink = '/send-code';

  useEffect(() => {
    if (helperService.isLoggedIn()) {
      navigate("/events");
    }
  });

  const login = () => {
    let payload:AuthDto = {
      email: email,
      password: password
    };

    apiService.login(payload).then((response: any) => {
      if (response._id) {
        const queryParameters = new URLSearchParams(window.location.search);
        const redirect = queryParameters.get("r");

        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/events");
        }
        
      }
    }).catch((error:any) => {
      toast.show({
        title: "Error",
        content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
        duration: 10000,
        type: "fail"
      });
    });
  }

  return (
    <div className="Login Signup">
    <Helmet>
      <title>Login</title>
      <meta name="description" content="Login to your Eager Dog account." />
      <meta name="robots" content="index, follow" />
    </Helmet>
      <div className="images">
        <div className="logo"><Link to="/"><img src={logo} alt="Eager Dog" /></Link></div>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }} className="dogCards">
          <div className="cardRow">
            {helperService.topDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
          <div className="cardRow">
            {helperService.bottomDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
        </motion.div>
        <motion.div initial={{ opacity: 0, translateY: "-300px" }} whileInView={{ opacity: 1, translateY: "-50px" }} transition={{ delay: 0.5 }} className="dogBanner">
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 1 }} className="bannerInner">
            <div className="small">The Best Place</div>
            <div className="large">for your <span>Dog</span></div>
            <div className="dogWrap">
              <div className="portrait"><img src={helperService.allDogs[2].image} alt={"Dog Portrait of " + helperService.allDogs[2].name} /></div>
            </div>
            <div className="dogName">Stan</div>
          </motion.div>
        </motion.div>
      </div>
      <div className="content">
        <div className="inner">
          <div className="logo"><img src={logo} alt="Eager Dog" /></div>
          <h1>Login</h1>
          <div className="form">
            <form onSubmit={(e) => { e.preventDefault(); login(); }}>
              <Input type="email" onChange={(e:any) => { setEmail(e.target.value); }} icon={emailIcon} label="Email" placeholder="Enter your email" />
              <Input type="password" onChange={(e:any) => { setPassword(e.target.value); }} icon={keyIcon} label="Password" placeholder="Enter your password" />
              <div className="forgot">
                <Link to={resetPasswordLink}>Forgot your password?</Link>                               
              </div>
              <div className="actions">
                <button type="submit">Login</button>
              </div>
              <div className="register">Don't have an account yet? <Link to={registerLink}>Sign Up</Link></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
