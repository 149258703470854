import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Modal, useModal } from "../../components/Modal/Modal";
import { helperService } from "../../services/helper.service";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import "./Home.scss";

import logo from "../../assets/logo.svg";

import eventCard1 from "../../assets/eventCard1.png";
import eventCard2 from "../../assets/eventCard2.png";
import eventCard3 from "../../assets/eventCard3.png";
import eventCard4 from "../../assets/eventCard4.png";

import dogForm from "../../assets/dogForm.png";
import dogForm2 from "../../assets/dogForm2.png";
import dogFormList from "../../assets/dogFormList.png";
import cards from "../../assets/cards.png";
import kennelCard from "../../assets/kennelCard.png";
import cloud from "../../assets/cloud.png";
import eventPage from "../../assets/eventPage.png";
import aboutEagerDog from "../../assets/aboutEagerDog.png";
import ourFirstSteps from "../../assets/ourFirstSteps.png";
import goal from "../../assets/goal.png";

import heartTag from "../../assets/icons/heartTag.svg";
import responsive from "../../assets/icons/responsive.svg";
import bookmark from "../../assets/icons/bookmark.svg";
import cash from "../../assets/icons/cash.svg";
import cloudSVG from "../../assets/icons/cloud.svg";
import download from "../../assets/icons/download.svg";
import pages from "../../assets/icons/pages.svg";
import pawSmall from "../../assets/icons/pawSmall.svg";
import pie from "../../assets/icons/pie.svg";
import registerPhone from "../../assets/icons/registerPhone.svg";
import searchList from "../../assets/icons/searchList.svg";
import starPC from "../../assets/icons/starPC.svg";
import trophy from "../../assets/icons/trophy.svg";
import userSettings from "../../assets/icons/trophy.svg";

import instagram from "../../assets/icons/instagram.svg";
import facebook from "../../assets/icons/facebook.svg";
import twitter from "../../assets/icons/twitter.svg";

import paw from "../../assets/paw.svg";
import bone from "../../assets/bone.svg";

import { apiService } from "../../services";

import Input from "../../components/Input/Input";
import Phone from "../../components/Phone/Phone";
import Checkbox from "../../components/Checkbox/Checkbox";
import UserMenu from "../../components/UserMenu/UserMenu";
import Dropdown, { IOption } from "../../components/Dropdown/Dropdown";
import { toast } from "../../components/Toast/ToastManager";

interface IProps {
}

interface featureItem {
  style: string;
  title: string;
  list: featureListItem[];
  images: featureItemImage[];
}

interface featureListItem {
  text: string;
  icon: string;
}

interface featureItemImage {
  file: string;
  delay: number;
}

interface checkboxList {
  id: string,
  name: string,
  checked: boolean
}

const Home: React.FC<IProps> = (props) => {
  useCalendlyEventListener({
    onEventScheduled: (e) => { subscribe(); },
  });

  const navigate = useNavigate();

  const { isShown, toggle } = useModal();

  const [ firstName, setFirstName ] = useState<string>("");
  const [ lastName, setLastName ] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [club, setClub] = useState<string>("");
  const [epy, setEpy] = useState<IOption>({ value: "0 - 5", id: "0 - 5" });
  const [epyError, setEpyError] = useState<boolean>(false);

  const epyOptions: IOption[] = [{ id: "0 - 5", value: "0 - 5" }, { id: "6 - 10", value: "6 - 10" }, { id: "10 - 20", value: "10 - 20" }, { id: "Over 20", value: "Over 20" }];

  const [currentStep, setCurrentStep] = useState<number>(0);

  const showList: checkboxList[] = [
    { id: "conformation", name: "Conformation", checked: false },
    { id: "nosework", name: "Nosework", checked: false },
    { id: "agility", name: "Agility", checked: false },
    { id: "lure-coursing", name: "Lure Coursing", checked: false },
    { id: "obedience", name: "Obedience", checked: false },
    { id: "rally-obedience", name: "Rally Obedience", checked: false },
    { id: "dock-jumping", name: "Dock Jumping", checked: false },
    { id: "weight-pull", name: "Weight Pull", checked: false },
    { id: "fetch", name: "Fetch", checked: false },
    { id: "fast-cat", name: "Fast Cat", checked: false },
    { id: "other", name: "Other", checked: false }
  ];


  const eventList: checkboxList[] = [
    { id: "UKC", name: "UKC", checked: false },
    { id: "AKC", name: "AKC", checked: false },
    { id: "CKC", name: "CKC", checked: false },
    { id: "Other", name: "Other", checked: false }
  ];

  const [showChecked, setShowChecked] = useState(showList);
  const [eventChecked, setEventChecked] = useState(eventList);

  useEffect(() => {
    if (helperService.isLoggedIn()) {
      navigate("/events");
    }
  });

  const resetFormData = () => {
    setEpyError(false);
    setEventChecked(eventList);
    setShowChecked(showList);
    setPhone("");
    setClub("");
    setEpy({ value: "0 - 5", id: "0 - 5" });
    setCurrentStep(0);
  }

  const handleShowChange = (e: any) => {
    let _showChecked = [...showChecked];

    let _checkbox:checkboxList = _showChecked.filter((value:checkboxList) => value.id === e.target.id)[0];
    _showChecked[_showChecked.indexOf(_checkbox)].checked = e.target.checked;

    setShowChecked(_showChecked);
  };

  const handleEventChange = (e: any) => {
    let _eventChecked = [...eventChecked];

    let _checkbox:checkboxList = _eventChecked.filter((value:checkboxList) => value.id === e.target.id)[0];
    _eventChecked[_eventChecked.indexOf(_checkbox)].checked = e.target.checked;

    setEventChecked(_eventChecked);
  };

  const checkHasError = () => {
    let shows: string[] = [];
    let events: string[] = [];

    showChecked.forEach((value) => {
      if (value.checked) {
        shows.push(value.id);
      }
    });

    eventChecked.forEach((value) => {
      if (value.checked) {
        events.push(value.id);
      }
    });

    if (epy.value === "") {
      setEpyError(true);

      toast.show({
        title: "Error",
        content: "Please select an option for 'Events per Year'",
        duration: 10000,
        type: "fail"
      });

      return true;
    } else {
      setEpyError(false);
    }

    if (shows.length === 0) {
        toast.show({
          title: "Error",
          content: "Please select at least one show type option",
          duration: 10000,
          type: "fail"
        });

        return true;
    }

    if (events.length === 0) {
        toast.show({
          title: "Error",
          content: "Please select at least one sanctioned event option",
          duration: 10000,
          type: "fail"
        });

        return true;
    }

    return false;
  }

  const subscribe = () => {
    let shows: string[] = [];
    let events: string[] = [];
    let hasError: boolean = false;

    showChecked.forEach((value) => {
      if (value.checked) {
        shows.push(value.id);
      }
    });

    eventChecked.forEach((value) => {
      if (value.checked) {
        events.push(value.id);
      }
    });

    hasError = checkHasError();

    if (!hasError) {
      apiService
        .createSubscription({
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          club_name: club,
          events_per_year: epy.value,
          event_types: shows,
          sanctioned_event_types: events
        })
        .then((response: any) => {
          if (response._id) {
            toast.show({
              title: "Subscribed",
              content: "Thank you for booking a demo! Please check your email for updates on your demo and Eagerdog",
              duration: 10000,
              type: "success"
            });

            setCurrentStep(0);
            toggle();
          }
        }).catch((error: any) => {
          toast.show({
            title: "Error",
            content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
            duration: 10000,
            type: "fail"
          });
        });
    }
  };

  const subscribeForm = (
    <React.Fragment>
      {currentStep === 0 && <div className="subscribe-form">
        <p>Thanks for your interest!</p>
        <p>We have a few more questions, if you don't mind</p>
        <form onSubmit={(e) => { e.preventDefault(); /*subscribe();*/ if (!checkHasError()) { setCurrentStep(1); } }}>
          <div className="row">
            <Input defaultValue={email} label="Email" id="email" type="email" required onChange={(e) => { e.stopPropagation(); setEmail(e.target.value); }} placeholder="Enter your email" />
          </div>
          <div className="row">
            <Input label="First Name" id="first_name" type="text" required onChange={(e) => { e.stopPropagation(); setFirstName(e.target.value); }} placeholder="Enter your First Name" />
            <Input label="Last Name" id="last_name" type="text" required onChange={(e) => { e.stopPropagation(); setLastName(e.target.value); }} placeholder="Enter your Last Name" />
          </div>
          <div className="row">
            <Phone label="Phone Number" value={phone} onChange={setPhone} />
            <Input label="Kennel Club Name" id="club" type="text" required onChange={(e) => { e.stopPropagation(); setClub(e.target.value); }} placeholder="Enter your Kennel Club Name" />
          </div>
          <Dropdown error={epyError} value={epy} onChange={(e: any, value :IOption) => { setEpy(value); }} label="Events per Year" options={epyOptions} placeholder="How many events do you run per year?" />
          <div className="checkboxList">
            <span className="labelMain required">What type of shows do you run?</span>
            <div className="checkboxWrap">
              {showList.map((value: checkboxList, index: number) => {
                return (<Checkbox key={index} id={value.id} onChange={handleShowChange} value={showChecked[index].checked} label={value.name} />);
              })}
            </div>
          </div>
          <div className="checkboxList">
            <span className="labelMain required">Do you run any sanctioned events?</span>
            <div className="checkboxWrap">
              {eventList.map((value: checkboxList, index: number) => {
                return (<Checkbox key={index} id={value.id} onChange={handleEventChange} value={eventChecked[index].checked} label={value.name} />);
              })}
            </div>
          </div>
          <div className="actions">
            <input type="submit" value="Book a Time" />
          </div>
        </form>
      </div>}
      {currentStep === 1 && <div className="calendarWrap">
        <InlineWidget url="https://calendly.com/eagerdog" />
      </div>}
    </React.Fragment>
  );

  const features: featureItem[] = [
    {
      style: "registration",
      title: "Enhanced Registration",
      list: [
        { icon: heartTag, text:"Online and offline registration" },
        { icon: pie, text:"Real-time reports on registrations, available space, and total revenue" },
        { icon: registerPhone, text:"Exhibitors or club administrators can update registrations at any time" }
      ],
      images: [
        { file: dogForm2, delay: 0.1 },
        { file: dogFormList, delay: 0.3 }
      ],
    },
    {
      style: "exhibitor",
      title: "Better Exhibitor Experience",
      list: [
        { icon: responsive, text:"No need to fill out and mail in paper registrations" },
        { icon: cash, text:"Easy payment methods : Use Credit Card, Apple Pay, Google Pay and more!" },
        { icon: userSettings, text:"Easily manage all your registrations in one place" }
      ],
      images: [
        { file: cards, delay: 0.1 },
      ],
    },
    {
      style: "reduce",
      title: "Reduce Paperwork & Save Time",
      list: [
        { icon: bookmark, text:"Judges Books are automatically created as registrations happen" },
        { icon: trophy, text:"Group and Best in Show Judges Books are updated and managed automatically" },
        { icon: download, text:"Manually update / edit judges books or print them at any time if you need" }
      ],
      images: [
        { file: kennelCard, delay: 0.1 },
      ],
    },
    {
      style: "awareness",
      title: "Increase Revenue & Awareness",
      list: [
        { icon: pawSmall, text:"Completely branded Kennel Club page to show your events, course and classes" },
        { icon: searchList, text:"Easy search and discovery tools allow more exhibitors to find your events on Eager Dog" }
      ],
      images: [
        { file: eventCard3, delay: 0.1 },
        { file: eventCard4, delay: 0.25 }
      ],
    },
    {
      style: "modern",
      title: "Modern Architecture & Security",
      list: [
        { icon: cloudSVG, text:"Cloud based architecture allows you and your team to access your data from anywhere" },
        { icon: starPC, text:"No more installing on only one computer! Enterprise architecture and security ensuring that your data is safe" },
        { icon: pages, text:"Automated backups make sure that no data is ever lost!" }
      ],
      images: [
        { file: cloud, delay: 0.1 },
      ],
    }
  ];

  return (
    <div className="Home">
      <Helmet>
        <title>Eager Dog - Say goodbye to paperwork and hello to simplicity with Eager Dog</title>
        <meta name="description" content="Eager Dog is a software platform for Kennel Clubs to accept online registrations and run their events more efficiently." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="atfWrap">
        <div className="atf">
          <div className="header">
            <div className="logo"><img src={logo} alt="Eager Dog" /></div>
            <UserMenu />
          </div>
          <div className="paw"><img src={paw} alt="Paw Print" /></div>
          <div className="bone"><img src={bone} alt="Dog Bone" /></div>
           <div className="inner">
             <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.5 }} className="text">
               <div className="release"><span>Beta Release</span></div>
               <h1>Say goodbye to paperwork and hello to simplicity with Eager Dog</h1>
               <p>Eager Dog is a software platform for Kennel Clubs to accept online registrations and run their events more efficiently.</p>
                <form onSubmit={(e:any) => { e.preventDefault(); resetFormData(); toggle(); }}>
                 <Input required onChange={(e:any) => { setEmail(e.target.value); }} type="email" placeholder="Enter your email" />
                 <button type="submit">Request a Demo</button>
               </form>
             </motion.div>
             <div className="images">
               <motion.div initial={{ opacity: 0, translateY: "100px" }} whileInView={{ opacity: 1, translateY: "0px" }} transition={{ delay: 0.3 }} className="card1"><img src={eventCard1} alt="Eager Dog Event Example 1" /></motion.div>
               <motion.div initial={{ opacity: 0, translateY: "100px", rotateZ:"-20deg" }} whileInView={{ opacity: 1, translateY: "0px", rotateZ:"8deg" }} transition={{ delay: 0.1 }} className="card2"><img src={eventCard2} alt="Eager Dog Event Example 2" /></motion.div>
             </div>
           </div>
        </div>
      </div>
      <div className="banner">
        <h2>The best way to run your Kennel Club’s next event!</h2>
        <p>The Eager Dog platform will help ensure your team spends more time helping exhibitors have an amazing experience and less time buried in manual paperwork and trying to keep the event organized.</p>
      </div>
      <div className="btfWrap">
        <div className="paw pawOne"><img src={paw} alt="Paw Print"/></div>
        <div className="paw pawTwo"><img src={paw} alt="Paw Print"/></div>
        <div className="btf">
           <div className="text">
            <h3>We’re making dog events easy to run!</h3>
            <p>Eliminate the endless paperwork and manual efforts required to run your next show.</p>
          </div>
          <div className="images">
            <motion.div initial={{ opacity: 0, translateY: "400px" }} whileInView={{ opacity: 1, translateY: "0px" }} transition={{ delay: 0.3 }} className="dogForm"><img src={dogForm} alt="Dog Form"/></motion.div>
          </div>
        </div>
      </div>
      {features.map((f:featureItem, i1:number) => {
        return(
          <div key={i1} className={i1 % 2 === 0 ? "feature flipped " + f.style : "feature " + f.style}>
            <div className="inner">
              <h4>{f.title}</h4>
              <div className="split">
                <div className="text">
                  <ul>
                  {f.list.map((l:featureListItem, i2:number) => {
                    return(<li key={i2}><div className="icon"><img src={l.icon} alt="Feature Icon" /></div><span>{l.text}</span></li>);
                  })}
                  </ul>
                </div>
                <div className="image">
                  {f.images.map((i:featureItemImage, i3:number) => {
                    return(<motion.div key={i3} initial={{ opacity: 0, translateY: "200px" }} whileInView={{ opacity: 1, translateY: "0px" }} transition={{ delay: i.delay }} className={"featureImage i"+i3}><img src={i.file} alt="Feature Example" /></motion.div>);
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="btfWrap beta">
        <div className="paw pawOne"><img src={paw} alt="Paw Print"/></div>
        <div className="paw pawTwo"><img src={paw} alt="Paw Print"/></div>
        <div className="btf">
           <div className="text">
            <h4>Join our beta program</h4>
            <p>Run an event on the Eager Dog platform this summer!</p>
            <form onSubmit={(e:any) => { e.preventDefault(); resetFormData(); toggle(); }}>
              <Input required onChange={(e:any) => { setEmail(e.target.value); }} type="email" placeholder="Enter your email" />
              <button type="submit">Request a Demo</button>
            </form>
          </div>
          <div className="images">
            <motion.div initial={{ opacity: 0, translateY: "400px" }} whileInView={{ opacity: 1, translateY: "0px" }} transition={{ delay: 0.3 }} className="dogForm"><img src={eventPage} alt="Event Page"/></motion.div>
          </div>
        </div>
      </div>
      <div className="aboutUs">
        <h4>About Us</h4>
        <div className="inner">
          <div className="tiles">
            <div className="tile">
              <div className="content">
                <div className="title">Eager Dog</div>
                <p>A software platform created specifically for the dog racing community to improve the experience for all participants.</p>
              </div>
              <div className="image"><img src={aboutEagerDog} alt="About Eager Dog" /></div>
            </div>
            <div className="tile">
              <div className="content">
                <div className="title">Our first step</div>
                <p>Building a world-class platform for any Kennel Club to successfully run events, courses and classes.</p>
              </div>
              <div className="image"><img src={ourFirstSteps} alt="Our First Steps" /></div>
            </div>
            <div className="tile">
              <div className="content">
                <div className="title">Goal</div>
                <p>The plan is to create the largest community for dog lovers, competitors, judges, and kennel clubs!</p>
              </div>
              <div className="image"><img src={goal} alt="Our Goal" /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="inner">
          <div className="details">
            <img src={logo} alt="Eager Dog Logo" />
            <p>Eager Dog is a software platform for Kennel Clubs to run their events, classes and courses</p>
            <span>Copyright {new Date().getFullYear()} Eagerdog Inc</span>
          </div>
          <div className="contact">
            <div className="title">Contact Us</div>
            <div className="line"><a href="mailto:info@eagerdog.com">info@eagerdog.com</a></div>
          </div>
          <div className="socials">
            <div className="title">Social</div>
            <ul>
              <li><a rel="noreferrer" target="_blank" href="http://www.instagram.com/eagerdoginc"><img src={instagram} alt="Eager Dog Instagram" /></a></li>
              <li><a rel="noreferrer" target="_blank" href="http://www.facebook.com/eagerdoginc"><img src={facebook} alt="Eager Dog Facebook" /></a></li>
              <li><a rel="noreferrer" target="_blank" href="http://www.twitter.com/eagerdoginc"><img src={twitter} alt="Eager Dog Twitter" /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        className={currentStep === 0 ? "subscribeForm": "subscribeForm hiddenForm"}
        isShown={isShown}
        hide={toggle}
        modalContent={subscribeForm}
      />
    </div>
  );
};

export default Home;
