import React, { useEffect, useState, useMemo } from "react";
import { Constants } from "@eagerdog/constants";
import { IScore, INoseworkScore } from "@eagerdog/interfaces";

import Checkbox from "src/components/Checkbox/Checkbox";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Input from "src/components/Input/Input";
import StopWatch from "../StopWatch/StopWatch";

import "./NoseworkResults.scss";

interface IFault {
  name: string,
  amount: number,
  id: string
}

interface IProps {
  result: IScore,
  onChange(result: INoseworkScore): void
}

const NoseworkResults: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const [result, setResult] = useState<IOption>({ value: "None", id: "None" });
  const [reason, setReason] = useState<string>("");
  const [nqReason, setNqReason] = useState<IOption>({ value: "None", id: "None" });
  const [excluded, setExcluded] = useState<boolean>(false);

  const [time, setTime] = useState<string>("");

  const resultOptions:IOption[] = useMemo(() => [
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "NQ"},
    { id: Constants.score_result_type.disqualified, value: "Disqualified" },
    { id: Constants.score_result_type.excused, value: "Excused" },
    { id: Constants.score_result_type.absent, value: "Absent"}
  ], []);

  const nqReasons: IOption[] = useMemo(() => [
    { id: "None", value: "None" },
    { id: Constants.nq_reason.incorrect_call, value: Constants.nq_reason.incorrect_call },
    { id: Constants.nq_reason.false_alert, value: Constants.nq_reason.false_alert },
    { id: Constants.nq_reason.timed_out, value: Constants.nq_reason.timed_out },
    { id: Constants.nq_reason.exposed_hide, value: Constants.nq_reason.exposed_hide },
    { id: Constants.nq_reason.miss, value: Constants.nq_reason.miss },
    { id: Constants.nq_reason.too_many_faults, value: Constants.nq_reason.too_many_faults }
  ], []);

  const [faults, setFaults] = useState<IFault[]>([
    { id: Constants.score_fault_type.handler_error, name: "Handler Error", amount: 0 },
    { id: Constants.score_fault_type.safety_concern, name: "Safety Concern", amount: 0 },
    { id: Constants.score_fault_type.compromised_area, name: "Compromised Area", amount: 0 },
    { id: Constants.score_fault_type.incorrect_response, name: "Incorrect Response", amount: 0 },
    { id: Constants.score_fault_type.fringe_response, name: "Fringe Response", amount: 0 },
    { id: Constants.score_fault_type.aggressive_response, name: "Aggressive Response", amount: 0 }
  ]);

  const setFault = (index: number, value: string) => {
    let _faults = JSON.parse(JSON.stringify(faults));

    _faults[index].amount = value;

    setFaults(_faults);
  }

  useEffect(() => {
    if (!loaded) {
      if (props.result && props.result.nosework) {
        for (let ro in resultOptions) {
          if (resultOptions[ro].id === props.result.nosework.result_type) {
            setResult(resultOptions[ro]);
            break;
          }
        }

        if (props.result.nosework.result_type === Constants.score_result_type.not_qualified) {
          for (let nqR in nqReasons) {
            if (nqReasons[nqR].id === props.result.nosework.result_reason) {
              setNqReason(nqReasons[nqR]);
              break;
            }
          }
        } else {
          setReason(props.result.nosework.result_reason || "");
        }

        let _faults = JSON.parse(JSON.stringify(faults));

        for (let f in props.result.nosework.faults) {
          let _fault = props.result.nosework.faults[f];

          for (let ff in _faults) {
            if (_faults[ff].id === _fault.fault_type) {
              _faults[ff].amount = _fault.fault_count;
              break;
            }
          }
        }

        setFaults(_faults);

        setTime(props.result?.nosework?.search_time || "");

        setExcluded(props.result.nosework.excluded_from_placement || false);
      }

      setLoaded(true);
    }
  }, [loaded, faults, nqReasons, props.result, resultOptions]);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        result_type: result.id,
        result_reason: result.id === Constants.score_result_type.not_qualified ? nqReason.value : reason,
        search_time: time,
        faults: faults.map((f: IFault) => {
          return {
            fault_type: f.id,
            fault_count: f.amount
          };
        }),
        excluded_from_placement: excluded,
      });

      setHasChanged(false);
    }
  }, [hasChanged, result, reason, nqReason, time, faults, props, excluded]);

  const needsReason:string[] = [
    Constants.score_result_type.disqualified,
    Constants.score_result_type.excused
  ];

  return (
    <div className="NoseworkResults">
      <Dropdown value={result} label={"Result"} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} options={resultOptions} placeholder="" />
      {result.id === Constants.score_result_type.qualified && <StopWatch time={time} onChange={(value: string) => { setTime(value); setHasChanged(true); }} />}
      {result.id === Constants.score_result_type.not_qualified && 
        <div className="reason">
          <Dropdown value={nqReason} label={"NQ Reason"} onChange={(e: any, value: IOption) => { setNqReason(value); setHasChanged(true); }} options={nqReasons} placeholder="" />
        </div>
      }
      {needsReason.includes(result.id) &&
        <div className="reason">
          <Input
            label="Reason"
            id="reason"
            type="text"
            onChange={(e) => {
              e.stopPropagation();
              setReason(e.target.value);
              setHasChanged(true);
            }}
            placeholder={"Enter a reason for the result"}
            defaultValue={reason}
          />
        </div>
      }
      <div className="faults-title">Faults</div>
      <div className="faults">
        {faults.map((f: IFault, index:number) => {
          return(
            <div key={"fault"+index} className="fault">
              <Input
                label={f.name}
                id={"fault"+index}
                type="number"
                onChange={(e) => {
                  e.stopPropagation();
                  setFault(index, e.target.value);

                  setHasChanged(true);
                }}
                defaultValue={f.amount.toString()}
                min={0}
              />
            </div>
          );
        })}
      </div>
      <Checkbox
        onChange={(e) => {
          setExcluded(!excluded);
          setHasChanged(true);
        }}
        value={excluded}
        id="excluded"
        label={"Exclude this result from placements"}
      />
    </div>
  );
};

export default NoseworkResults;