import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IQuery, UserCreateDto } from "@eagerdog/interfaces";

import { apiService } from 'src/services';
import { helperService } from "src/services/helper.service";
import { useDebounce } from "src/hooks/useDebounce";

import { toast } from '../Toast/ToastManager';
import Input from "../Input/Input";
import Phone from "../Phone/Phone";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";

import "./UserSearch.scss";

interface IProps {
  event: string
}

const UserSearch: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const [emailSearch, setEmailSearch] = useState<string>("");
  const dEmailSearch = useDebounce<string>(emailSearch, 800);

  const [userList, setUserList] = useState<[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [createLoading, isCreateLoading] = useState<boolean>(false);

  const [createNewUser, setCreateNewUser] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [isJunior, setIsJunior] = useState<boolean>(false);
  const [parentName, setParentName] = useState<string>("");

  const searchUsers = (userEmail: string) => {
    setLoading(true);

    let query:IQuery = {
      $limit: 3,
      sort: [{
         attribute_name: "email",
         sort: "desc"
      }],
      query: {
        $and: [
          {
            attribute_name: "email",
            attribute_value: {
              operator: "$regex",
              value: userEmail
            }
          }, {
            attribute_name: "user_type",
            attribute_value: {
              operator: "$eq",
              value: "owner"
            }
          }
        ]
      }
    };

    apiService.getUsers(query).then((response: any) => {
      setUserList(response);
    }).finally(() => {
      setLoading(false);
    });
  }

  const createUser = () => {
    isCreateLoading(true);

    let payload:UserCreateDto = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      display_name: firstName + " " + lastName,
      phone: phone
    };

    if (isJunior) {
      payload.is_junior = isJunior;
      payload.junior_numbers = [];
      payload.parent_name = parentName;
    }

    apiService.createUserByClubManager(payload).then((response) => {
      toast.show({
        title: "Create User",
        content: "User created successfully",
        duration: 10000,
        type: "success"
      });

      navigate("/e/" + props.event + "/register?uid=" + response._id + "&uemail=" + encodeURIComponent(response.email)+"&junior="+ (response.is_junior ? "true" : "false") + "&claimed=false");
    }).catch((e: any) => {
      toast.show({
        title: "Create User",
        content: e.response?.data?.message ? e.response?.data?.message : "Unable to Create User",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    }).finally(() => {
      isCreateLoading(false);
    });
  }

  const pdEmailSearch = helperService.usePrevious(dEmailSearch);

  useEffect(() => {
    if (emailSearch.length > 0 && dEmailSearch !== pdEmailSearch) {
      searchUsers(emailSearch);
    }
  }, [emailSearch, dEmailSearch, pdEmailSearch]);

  return(
    <div className="UserSearch">
      {!createNewUser && <div className="userSearchInner">
        <div className="title">User Search</div>
        <div className="description">To register a user for your event, search for them by email.</div>
        <form className="searchUsers" onSubmit={((e) => { setEmail(emailSearch); setCreateNewUser(true); })}>
          <Input type="email" onChange={(e) => { setEmailSearch(e.target.value); }} defaultValue={emailSearch} placeholder="users@email.com" />
          <Button type="submit">Create User</Button>
        </form>
        {!loading && emailSearch.length > 0 && userList.length === 0 && <div className="noUserFound">
          <span className="msg">No Users Found</span>
        </div>}
        {!loading && emailSearch.length > 0 && userList.length > 0 && <ul className="usersFound">
          {userList.map((user: any, index: number) => {
            return(<li key={index}><Link to={"/e/" + props.event + "/register?uid=" + user._id+"&uemail=" + encodeURIComponent(user.email) +"&junior="+ (user.is_junior ? "true" : "false") +"&claimed="+(user.auth_type === "password" ? "true" : "false")}>{user.email}</Link></li>);
          })}
        </ul>}
        {loading && <div className="loadWrap">
          <div id="loading"></div>
        </div>}
      </div>}
      {createNewUser && <div className="createNewUser">
        <div className="title">Create User</div>
        <form onSubmit={(e) => { e.preventDefault(); createUser(); }}>
          <div className="row">
            <Input required label={"Email"} type="email" onChange={(e) => { setEmail(e.target.value); }} placeholder="" defaultValue={email} />
          </div>
          <div className="row double">
            <Input required label={"First Name"} type="text" onChange={(e) => { setFirstName(e.target.value); }} placeholder="" />
            <Input required label={"Last Name"} type="text" onChange={(e) => { setLastName(e.target.value); }} placeholder="" />
          </div>
          <div className="row">
            <Phone required onChange={setPhone} value={phone} />
          </div>
          <div className="row junior">
            <Checkbox onChange={(e) => { setIsJunior(!isJunior); }} value={isJunior} id="isJunior" label="This is a junior account" />
            {isJunior && <Input required onChange={(e) => { setParentName(e.target.value); }} type="parentName" label="Parent Name" placeholder="What's your parents name?" />}
          </div>
          <div className="actions">
            <Button onClick={() => { setCreateNewUser(false); }} type="button">Back</Button>
            <Button isLoading={createLoading} type="submit">Create</Button>
          </div>
        </form>
      </div>}
    </div>
  );
}

export default UserSearch;