import React, { useEffect } from "react";
import { AxiosError } from "axios";

import { Modal, useModal } from "../Modal/Modal";

export interface ToastProps {
  id: string,
  destroy: () => void,
  title: string,
  content: string,
  duration?: number,
  errorDetails?: AxiosError | undefined,
  type: string
}

const Toast: React.FC<ToastProps> = (props) => {
  const { destroy, content, title, duration = 0, id } = props;
  const { isShown, toggle } = useModal();

  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      if (!isShown) {
        destroy();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [destroy, duration, isShown]);

  return (
    <div className={"Toast " + props.type + " "+ id}>
      <div className="toast-inner">
        <div className="toast-icon-wrap">
          <div className={"toast-icon " + props.type}>
          </div>
        </div>
        <div className="toast-content">
          <div className="toast-header">
            <div>{title}</div>
            <button className="close" onClick={destroy}></button>
          </div>
          <div className="toast-body">{content}</div>
          {props?.errorDetails?.response && <div className="errorDetails" onClick={() => { toggle(); }}>View Details</div>}
        </div>
      </div>
      {props?.errorDetails?.response && <Modal
        className="errorDetailsModal"
        isShown={isShown}
        hide={() => { toggle(); destroy(); }}
        modalContent={<div className="errorDetailsInner">
          <div className="errorDetailsWrap">
            {props.errorDetails && <code>{JSON.stringify(props.errorDetails.response)}</code>}
            <p>Copy the text above and <a href={"mailto:info@eagerdog.com?subject=I've encountered an error!&body=Add some more details about how you encountered the error here:%0D%0D%0D" + (props.errorDetails ? JSON.stringify(props.errorDetails?.response) : "") + "%0D%0D%0D"}>send it to our support team</a>, it will help them assist you. 🙂</p>
          </div>
        </div>}
      />}
    </div>
  );
};

export default Toast;